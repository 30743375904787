/* eslint-disable complexity */
import React, { useMemo, useState, useCallback } from 'react';
import {
  GQL_InvestigationCatalog,
  GQL_InvestigationCatalogEntry,
  InvestigationType,
  WorkShopTemplate,
} from '../../types/investigation';
import { getInvestigationLogo } from '../../utils/investigationLogos';
import Icon from '@ant-design/icons';
import Button from '../../shared/Button';
import * as S from './styles';
import { Col, Popover, message, Row, Tooltip, Popconfirm } from 'antd';
import { themeConfig } from '../../utils/theme';
import NewInvestigationModal from '../../components/AdiTemplatesPage/NewInvestigationModal';
import { gqlSchema } from '../../gql/schema';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FiHelpCircle, FiLock, FiPackage, FiStar, FiClock } from 'react-icons/fi';
import { FaGraduationCap } from 'react-icons/fa';
import { IoPieChart } from "react-icons/io5";

import { getStateIcon } from '../../utils/stateIcons';
import PreviewAssessmentModal from '../../components/AdiTemplatesPage/PreviewAssessmentModal';
import { cleanCoreIdeaCode } from '../../utils/utils';
import Switch from '../../shared/Switch';
// import { cleanCoreIdeaCode } from '../../utils/utils';

interface IAssessmentCard {
  investigation: GQL_InvestigationCatalog;
  action: () => void;
  loading?: boolean;
  actionText: string;
  cloneText?: string;
  available?: boolean;
  supportedStates?: { state: string; statements: string[] }[];
  showPreview?: boolean;
  isAdvancedSearch?: boolean;
  actionDisabled?: boolean;
}

const AssessmentCard = (props: IAssessmentCard) => {
  const {
    investigation,
    action,
    loading,
    actionText,
    cloneText,
    available = true,
    showPreview = false,
    isAdvancedSearch = false,
    actionDisabled = false,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(investigation.isTemplate || false);

  const toggleTemplate = useCallback(() => {
    setIsTemplate((prevState) => !prevState);
  }, []);

  const [updateInvestigationTemplate, {loading: loadingIsTemplate}] = useMutation<{ editInvestigationIsTemplate: GQL_InvestigationCatalogEntry }>(
    gqlSchema.InvestigationSchema.mutations.EDIT.editInvestigationIsTemplate,
    {
      onError: (err) => {
        message.error(err.message || 'Unexpected error');
      },
      onCompleted: (data) => {
        toggleTemplate();
      },
    }
  );

  const handleToggleTemplate = useCallback(() => {
    updateInvestigationTemplate({
      variables: {
        data: {
          investigationId: investigation.id,
          isTemplate: !isTemplate,
        },
      },
    });
  }, [updateInvestigationTemplate, investigation.id, isTemplate]);

  const hasInPersonOnlyStep = useMemo(() => investigation.steps?.some((s) => s.mode === 'INPERSON'), [
    investigation.steps,
  ]);

  const hasRemoteOnlyStep = useMemo(() => investigation.steps?.some((s) => s.mode === 'REMOTE'), [investigation.steps]);

  const createdRecently = useMemo(() => (investigation.firstPublishedAt ?? 0) > Date.now() - 1000 * 60 * 60 * 24 * 7, [
    investigation.firstPublishedAt,
  ]);

  const history = useHistory();

  const closeModal = useCallback(() => {
    setIsCloning(false);
    setModalOpen(false);
  }, [setIsCloning, setModalOpen]);

  const closePreviewModal = useCallback(() => {
    setPreviewOpen(false);
  }, [setPreviewOpen]);

  const cloneAction = useCallback(() => {
    setIsCloning(true);
    setModalOpen(true);
  }, [setIsCloning, setModalOpen]);

  const [cloneInvestigationFromTemplate, { loading: loadingCreation }] = useMutation<{
    cloneInvestigation: GQL_InvestigationCatalogEntry;
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.cloneInvestigation, {
    onError: (err) => {
      message.error(err.message || 'Unexpected error');
    },
    onCompleted: ({ cloneInvestigation }) => {
      history.push(`/adi-assessments/edit/${cloneInvestigation.id}/core`);
    },
    refetchQueries: ['getInvestigationDrafts', 'getInvestigationCatalog'],
  });

  const handleUseTemplate = useCallback(
    (name: string, type: InvestigationType, workShopTemplate?: WorkShopTemplate, isAssessment = false) => {
      cloneInvestigationFromTemplate({
        variables: {
          cloneinvestigationDraft: {
            id: investigation.id,
            title: name,
            description: investigation?.description || isAssessment ? '' : 'Investigation Description',
            type,
            workShopTemplate,
            isAssessment,
          },
        },
      });
    },
    [cloneInvestigationFromTemplate, investigation],
  );

  const hasOnlyStep = hasInPersonOnlyStep || hasRemoteOnlyStep;

  const popoverKitUrl = useMemo(
    () => (
      <Popover
        content={
          <>
            <S.PopoverText>This assessment has a kit available.</S.PopoverText>
            <S.PopoverText>
              You can buy it from{' '}
              <a href={investigation.kitUrl} target="_blank" rel="noreferrer noopener">
                here
              </a>
              .
            </S.PopoverText>
          </>
        }
      >
        <a
          href={investigation.kitUrl}
          target="_blank"
          rel="noreferrer noopener"
          style={{ height: 24 }}
          data-cy="components-investigation-card-kit-url"
        >
          <FiPackage color={themeConfig.primaryColor.background} size={24} fontWeight="bold" />
        </a>
      </Popover>
    ),
    [investigation.kitUrl],
  );

  const popoverTeacherFavorite = useMemo(
    () => (
      <Popover content={<S.PopoverText>Teacher favorite</S.PopoverText>}>
        <FiStar color="#FFD700" fill="#FFD700" size={24} />
      </Popover>
    ),
    [],
  );

  const popoverUnavailable = useMemo(
    () => (
      <Popover
        content={
          <S.PopoverText>
            Upgrade your subscription{' '}
            <a href="https://share.hsforms.com/1WIzZgPCwR7Snqj6opPDvTQ5gbsq" target="_blank" rel="noreferrer noopener">
              here
            </a>{' '}
            to unlock this assessment.
          </S.PopoverText>
        }
      >
        <FiLock color="#7B8CEA" size={24} />
      </Popover>
    ),
    [],
  );

  const popoverHasOnlyStep = useMemo(() => {
    return (
      <Popover
        content={
          <>
            <S.PopoverText>
              <>
                This assessment has parts
                <br />
                that are <strong>{hasInPersonOnlyStep ? 'paper' : 'paperless'}</strong> only
              </>
            </S.PopoverText>
          </>
        }
      >
        <FiHelpCircle color="#767676" size={24} />
      </Popover>
    );
  }, [hasInPersonOnlyStep]);

  const assessmentCoreIdeas = useMemo(() => {
    return (
      <Col span={8}>
        <Row>
          {investigation.coreIdeas?.map((coreIdea) => (
            <Col span={12} key={coreIdea.code}>
              <Tooltip title={coreIdea.text}>
                <S.BadgeContainer color="#FFAE51">{cleanCoreIdeaCode(coreIdea.code)}</S.BadgeContainer>
              </Tooltip>
            </Col>
          ))}
        </Row>
      </Col>
    );
  }, [investigation.coreIdeas]);

  const crosscuttingConcepts = useMemo(
    () => (
      <Col span={8}>
        <Row>
          {investigation.crosscuttingConcepts?.map((cc) => (
            <Col span={12} key={cc.code}>
              <Tooltip title={cc.text}>
                <S.BadgeContainer color="#20BC89">{cc.code}</S.BadgeContainer>
              </Tooltip>
            </Col>
          ))}
        </Row>
      </Col>
    ),
    [investigation.crosscuttingConcepts],
  );

  const practices = useMemo(
    () => (
      <Col span={8}>
        <Row>
          {investigation.practices?.map((practice) => (
            <Col span={12} key={practice.code}>
              <Tooltip title={practice.text}>
                <S.BadgeContainer color="#7B8CEA">{practice.code}</S.BadgeContainer>
              </Tooltip>
            </Col>
          ))}
        </Row>
      </Col>
    ),
    [investigation.practices],
  );

  const numberOfParts = useMemo(() => {
    const steps = investigation?.steps || [];

    if (!steps.length) {
      return null;
    }

    let partsCount = 0;

    for (const step of steps) {
      partsCount = partsCount + (step?.activities?.length || 0);
    }

    if (partsCount === 0) {
      return null;
    }

    const message = `${partsCount} ${partsCount === 1 ? 'part' : 'parts'}`;

    return (
      <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
        <Col>
          <IoPieChart color="#999" size={30} />
        </Col>
        <Col style={{ textAlign: 'center' }}>
          <span role="none" >{message}</span>
        </Col>
      </Col>
    );
  }, [investigation]);

  const assessmentFocus = useMemo(() => {
    return (
      <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
        <Col>
          <FaGraduationCap color="#999" size={30} />
        </Col>
        <Col span={12} style={{ textAlign: 'left' }}>
          <span role="none" >{investigation?.focus?.name}</span>
        </Col>
      </Col>
    );
  }, [investigation]);

  const assessmentDuration = useMemo(() => {
    return (
      <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
        <Col>
          <FiClock color="#999" size={30} />
        </Col>
        <Col>{investigation.time || '?'} minutes</Col>
      </Col>
    );
  }, [investigation]);

  const cardDetails = useMemo(() => {
    return (
      <>
        <Row gutter={[8, 8]} style={{ width: '100%', color: '#666', fontSize: '12px' }}>
          {investigation.focus && assessmentFocus}
          {investigation.time && assessmentDuration}
          {numberOfParts}
        </Row>
        <Row gutter={[8, 8]} style={{ width: '100%', color: '#666', fontSize: '12px', marginTop: '10px' }}>
          {investigation?.coreIdeas && assessmentCoreIdeas}
          {investigation?.crosscuttingConcepts && crosscuttingConcepts}
          {investigation?.practices && practices}
        </Row>
      </>
    );
  }, [
    investigation,
    assessmentFocus,
    numberOfParts,
    assessmentDuration,
    assessmentCoreIdeas,
    crosscuttingConcepts,
    practices,
  ]);

  const subscriptionPage = useCallback(() => {
    history.push('/manage-subscription');
  }, [history]);

  const title = `${investigation.discipline?.name || 'No Discipline'}: ${investigation.title || 'No Title'}`;
  return (
    <S.CardContainer
      key={investigation?.id}
      hasBanner={!!investigation.isDraft || !!investigation.markedForDeletion || !!createdRecently}
      hasIcon={hasOnlyStep || !!investigation.kitUrl || investigation.teacherFavorite || !available}
      data-cy="components-investigation-card"
    >
      {investigation.isDraft && <S.DraftBanner>Unpublished</S.DraftBanner>}
      {investigation.markedForDeletion && <S.DraftBanner deleted>To Be Deleted</S.DraftBanner>}
      <div className="iconsContainer">
        {!available && popoverUnavailable && !actionDisabled}
        {hasOnlyStep && popoverHasOnlyStep}
        {investigation.kitUrl && popoverKitUrl}
        {investigation.teacherFavorite && popoverTeacherFavorite}
      </div>
      {!investigation.markedForDeletion && createdRecently && (
        <S.RecentlyAddedBanner data-cy="components-investigation-card-recently-added-banner">
          Recently Added
        </S.RecentlyAddedBanner>
      )}
      <span role="none" >Grade: {investigation.discipline?.gradeBand.replaceAll(/tx\.|ngss\./gm, '') || 'No Grade'}</span>

      <S.IconsContainer>
        {!!props.supportedStates && (
          <S.StateIcons>
            {props.supportedStates.map(({ state, statements }) => (
              <S.StateIconContainer key={state}>
                <Tooltip
                  placement="rightTop"
                  overlayInnerStyle={{
                    maxHeight: '50vh',
                    overflow: 'auto',
                    width: '350px',
                  }}
                  title={
                    <>
                      {statements.map((statement, index) => (
                        <p key={index}>{statement}</p>
                      ))}
                    </>
                  }
                >
                  <Icon component={getStateIcon(state)} />
                </Tooltip>
              </S.StateIconContainer>
            ))}
          </S.StateIcons>
        )}

        <S.DisciplineIconContainer subject={investigation.discipline?.subject}>
          <Icon
            component={getInvestigationLogo({
              discipline: investigation.discipline?.name || '',
              subject: investigation.discipline?.subject || '',
            })}
          />
        </S.DisciplineIconContainer>
      </S.IconsContainer>

      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: investigation.description }}></p>
      {cardDetails}
      <Row justify="center" style={{ marginTop: 15, width: showPreview ? '80%' : '' }} gutter={[8, 8]}>
        {cloneText && (
          <Col xxl={12} xl={24}>
            <Button
              minHeight={40}
              block
              text={cloneText}
              onClick={cloneAction}
              loading={loading}
              theme={themeConfig.primaryOutlined}
            />
          </Col>
        )}
        {showPreview ? (
          <>
            <Col span={12}>
              <Button
                minHeight={40}
                block
                text="Preview"
                data-cy="components-investigation-card-preview-button"
                onClick={() => setPreviewOpen(true)}
                loading={loading}
                theme={themeConfig.primaryOutlined}
              />
            </Col>
            <Col span={12}>
              <Popover
                placement="top"
                trigger={actionDisabled ? 'hover' : ''}
                content={
                  <>
                    <p style={{ marginBottom: 10 }}>{`Upgrade your subscription to enable this assessment`}</p>
                    <Button text="Upgrade" onClick={subscriptionPage} theme={themeConfig.secondaryColor} block />
                  </>
                }
              >
                <div style={{ width: '100%' }}>
                  <Button
                    minHeight={40}
                    block
                    text={actionText}
                    data-cy="components-investigation-card-action-button"
                    onClick={action}
                    disabled={actionDisabled}
                    loading={loading}
                    icon={actionDisabled ? <FiLock /> : <React.Fragment />}
                    theme={themeConfig.primaryColor}
                  />
                </div>
              </Popover>
            </Col>
          </>
        ) : (
          <Col xxl={cloneText ? 12 : 24} xl={24}>
            <Popover
              placement="top"
              trigger={actionDisabled ? 'hover' : ''}
              content={
                <>
                  <p style={{ marginBottom: 10 }}>{`Upgrade your subscription to enable this assessment`}</p>
                  <Button text="Upgrade" onClick={subscriptionPage} theme={themeConfig.secondaryColor} block />
                </>
              }
            >
              <div style={{ width: '100%' }}>
                <Button
                  minHeight={40}
                  block
                  text={actionText}
                  data-cy="components-investigation-card-action-button"
                  onClick={action}
                  disabled={actionDisabled}
                  loading={loading}
                  icon={actionDisabled ? <FiLock /> : <React.Fragment />}
                  theme={themeConfig.primaryColor}
                />
              </div>
            </Popover>
          </Col>
        )}
        {!loadingIsTemplate && actionText === "Edit the Assessment" &&
        <Col xxl={12} xl={24}>
          <Popconfirm
            placement="topLeft"
            title={`Are you sure you want to ${isTemplate ? 'disable' : 'enable'} template?`}
            onConfirm={handleToggleTemplate}
            okText="Yes"
            cancelText="No"
          >
            <Switch
              type="text"
              title={<S.TitleInput>Enable as Template</S.TitleInput>}
              value={isTemplate}
              titleAlign="left"
            />
          </Popconfirm>
        </Col>}
      </Row>
      <NewInvestigationModal
        open={modalOpen}
        title={'Assessment'}
        onConfirm={handleUseTemplate}
        onClose={closeModal}
        loading={loadingCreation}
        subject={investigation.discipline?.subject}
        isCloning={isCloning}
        isAssessment={true}
      />
      {!isAdvancedSearch && (
        <PreviewAssessmentModal
          open={previewOpen}
          onClose={closePreviewModal}
          title={title}
          assessmentId={investigation.id}
        />
      )}
    </S.CardContainer>
  );
};

export default AssessmentCard;
