import { Col, message, Row, Result, Pagination } from 'antd';
import React, { useMemo, useState } from 'react';
import PageWithTitle from '../../shared/PageWithTitle';
import TemplatesHeader from './TemplatesHeader';
import * as S from './styles';
import Button from '../../shared/Button';
import Spacer from '../../shared/Spacer';
import CustomInvestigationLoading from '../../shared/CustomInvestigationLoading';
// import { investigationTemplates } from '../../gql/api/investigationTemplateMock';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_InvestigationCatalogEntry, InvestigationType, WorkShopTemplate, GQL_InvestigationCatalogEntryPaginated } from '../../types/investigation';
import NewInvestigationModal from './NewInvestigationModal';
import { useAuth } from '../../hooks/useAuth';
import { useEffect } from 'react';

export interface ITemplateFilters {
  field: 'subject';
  value: string;
}
const PAGE_SIZE = 30;
const AdiTemplatesPage = () => {
  const [filters, setFilters] = useState<ITemplateFilters[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const { user, isOrganizationAdiAdmin } = useAuth();

  useEffect(() => {
    if (isOrganizationAdiAdmin && !user.subscription?.customLibrary) history.push('/organization-users');
  }, [user, isOrganizationAdiAdmin, history]);

  const { data: investigationsAsTemplateData, loading: loadingTemplateInvestigations, refetch: refetchTemplates } = useQuery<
      {
        getInvestigationCatalogPaginated: GQL_InvestigationCatalogEntryPaginated;
      }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalogPaginated, {
      variables: {
        isAssessment: false,
        isTemplate: true,
        page: currentPage,
        pageSize: PAGE_SIZE,
      },
  });

  const [createInvestigationFromTemplate, { loading: loadingCreation }] = useMutation<{
    createInvestigationDraft: GQL_InvestigationCatalogEntry;
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.createInvestigationDraft, {
    onError: (err) => {
      message.error(err.message || 'Unexpected error');
    },
    onCompleted: ({ createInvestigationDraft }) => {
      history.push(`/adi-investigations/edit/${createInvestigationDraft.id}/core`);
    },
    refetchQueries: [
      { query: gqlSchema.InvestigationSchema.queries.CORE.getInvestigationDrafts },
      { query: gqlSchema.InvestigationSchema.queries.CORE.getInvestigationCatalog },
    ],
  });

  const loading = useMemo(() => loadingTemplateInvestigations, [loadingTemplateInvestigations]);
  const skeleton = useMemo(() => <CustomInvestigationLoading />, []);
  const notFound = useMemo(
    () => (
      <Result
        status="warning"
        subTitle="There are no templates available."
        extra={
          <Row justify="center">
            <Col span={24} style={{ display: 'table' }}>
              <Button
                style={{ display: 'table-cell', textAlign: 'center' }}
                type="primary"
                text="Goto Manage Investigations"
                onClick={() => history.push(`/adi-investigations/investigation-list`)}
              />
            </Col>
          </Row>
        }
      />
    ),
    [history],
  );
  // const templates = useMemo(() => {
  //   let filteredTemplates = investigationTemplates.filter((template) =>
  //     template.title.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase() || ''),
  //   );

  //   filters.forEach((filter) => {
  //     switch (filter.field) {
  //       case 'subject':
  //       default:
  //         filteredTemplates = filteredTemplates.filter((template) => template.subject === filter.value);
  //     }
  //   });

  //   return filteredTemplates;
  // }, [searchText, filters]);

  const combinedInvestigations = useMemo(() => {
    if (investigationsAsTemplateData !== undefined) {
      const filteredTemplates = investigationsAsTemplateData?.getInvestigationCatalogPaginated?.list.filter((template) => {
        if (filters.length) {
          return (filters.every((filter) => template?.discipline?.subject.includes(filter.value))
          && template.title.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase() || ''));
        } else {
          return template.title.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase() || '');
        }
      });
      // Map and filter out specific fields from DB templates
      const dbTemplates = filteredTemplates.map(template => ({
        ...template,
        discipline: {
            id: template?.discipline?.id,
            name: template?.discipline?.name,
            subject: template?.discipline?.subject,
        }
        // Add other fields you want to include here
      }));
      return [...dbTemplates];
    }
  }, [investigationsAsTemplateData, searchText, filters]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUseTemplate = (
    name: string,
    type: InvestigationType,
    workShopTemplate?: WorkShopTemplate,
    isAssessment = false,
  ) => {
    createInvestigationFromTemplate({
      variables: {
        investigationDraft: {
          title: name,
          description: selectedTemplate?.description,
          discipline: {
            id: selectedTemplate?.discipline?.id,
            name: selectedTemplate?.discipline?.name,
          },
          coreIdeas: selectedTemplate?.coreIdeas.map(({ __typename, ...rest }: { __typename: any; [key: string]: any }) => rest),
          practices: selectedTemplate?.practices.map(({ __typename, ...rest }: { __typename: any; [key: string]: any }) => rest),
          crosscuttingConcepts: selectedTemplate?.crosscuttingConcepts.map(({ __typename, ...rest }: { __typename: any; [key: string]: any }) => rest),
          standards: selectedTemplate?.standards.map(({ __typename, fullStatement, ...rest }: { __typename: any; fullStatement: any; [key: string]: any }) => rest),
          nextGenerationStandards: selectedTemplate?.nextGenerationStandards.map(({ __typename, fullStatement, ...rest }: { __typename: any; fullStatement: any; [key: string]: any }) => rest),
          type,
          workShopTemplate,
          isAssessment,
        },
      },
    });
  };

  return ( loading ? skeleton : combinedInvestigations && (combinedInvestigations?.length > 0 || filters.length > 0) ?
    (<PageWithTitle
      title={
        <TemplatesHeader
          refreshMethod={refetchTemplates}
          title="Investigation"
          filters={filters}
          setFilters={setFilters}
          updateSearchText={setSearchText}
        />
      }
    >
      <Row gutter={[24, 24]}>
        {combinedInvestigations?.map((template) => (
          <Col md={8} xs={24} sm={12} key={template.id}>
            <S.TemplateCard>
              <>
                <S.InvestigationIconTemplate discipline={template?.discipline?.name || "Math"} subject={template?.discipline?.subject || "Math"} size={75} />
              </>
              <h1>{template.title}</h1>
              <h2>{template.description}</h2>
              <Spacer />
              <div>
                <Button
                  text="Use Template"
                  onClick={() => {
                    setSelectedTemplate(template);
                    handleModalOpen();
                  }}
                />
              </div>
            </S.TemplateCard>
          </Col>
        ))}
      </Row>

      <Spacer />
      <Row justify="center">
        <Pagination
          total={investigationsAsTemplateData?.getInvestigationCatalogPaginated?.total}
          pageSize={PAGE_SIZE}
          onChange={(res) => {
            setCurrentPage(res);
            setTimeout(() => {
              refetchTemplates();
            }, 0);
          }}
          current={currentPage}
          showSizeChanger={false}
        />
      </Row>

      <NewInvestigationModal
        title="Investigation"
        open={modalOpen}
        onConfirm={handleUseTemplate}
        onClose={handleModalClose}
        loading={loadingCreation}
        subject={combinedInvestigations?.find((t) => t.id === selectedTemplate?.id)?.discipline?.subject}
        isCloning={false}
      />
    </PageWithTitle>):(notFound)
  );
};

export default AdiTemplatesPage;
