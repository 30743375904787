import { Col, Layout, message, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import InvestigationContent from '../../shared/InvestigationContent';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import PreviewSettingsSider from './PreviewSettingsSider';
import InvestigationBook, { IInvestigationBookStage, InvestigationBookLoading } from '../../shared/InvestigationBook';
import { RcFile } from 'antd/lib/upload';
import { debounce } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_InvestigationCatalogEntry, GQL_InvestigationCatalogStepEntry } from '../../types/investigation';
import { clearKeyFromObject } from '../../utils/investigation';
import * as S from './styles';

type Props = RouteComponentProps<{
  investigationId: string;
}>;

const AdiEditInvestigationPreviewPage: React.FC<Props> = (props) => {
  const { investigationId } = props.match.params;
  const history = useHistory();
  const [investigationDescription, setInvestigationDescription] = useState<string>();
  const [previewImage, setPreviewImage] = useState<RcFile>();
  const [standardsPDF, setStandardsPDF] = useState<RcFile>();
  const [suppliesPDF, setSuppliesPDF] = useState<RcFile>();
  const [letterHomePDF, setLetterHomePDF] = useState<RcFile>();
  const [stages, setStages] = useState<IInvestigationBookStage[] | undefined>();

  const { data, loading, refetch } = useQuery<
    { getInvestigationDraftById: GQL_InvestigationCatalogEntry },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationDraftById, {
    fetchPolicy: 'network-only',
    variables: {
      id: investigationId,
    },
    onCompleted: ({ getInvestigationDraftById }) => {
      setStages([...clearKeyFromObject(getInvestigationDraftById).steps]);
      setInvestigationDescription(getInvestigationDraftById.description);
    },
    onError: (err) => {
      message.error('There was an error getting the investigation: ' + err.message || 'Unexpected Error');
      history.goBack();
    },
  });

  const [editInvestigationSteps, { loading: loadingEditInvestigationSteps }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.DRAFT.editStepDraft,
  );

  const [editInvestigation, { loading: loadingEditInvestigation }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.DRAFT.editInvestigationDraft,
    {
      onCompleted: () => {
        data?.getInvestigationDraftById?.steps.forEach((step: GQL_InvestigationCatalogStepEntry, index: number) => {
          const stage = stages?.find((st: IInvestigationBookStage, i: number) => i === index);
          if (
            stage?.previewText !== step.previewText ||
            stage?.inPersonPreviewText !== step.inPersonPreviewText ||
            stage?.remotePreviewText !== step.remotePreviewText
          ) {
            editInvestigationSteps({
              variables: {
                stepDraft: {
                  id: step.id,
                  order: index + 1,
                  name: step.name,
                  investigationId,
                  previewText: stage?.previewText,
                  inPersonPreviewText: stage?.inPersonPreviewText,
                  remotePreviewText: stage?.remotePreviewText,
                },
                investigationDraftId: investigationId,
              },
            });
          }
        });
        message.success('Investigation saved successfully');
        refetch();
      },
      onError: (err) => {
        message.error('There was an error saving the investigation: ' + (err.message || 'Unexpected Error'));
      },
    },
  );

  const onSaveDraft = useCallback(() => {
    const investigationDraft = {
      id: investigationId,
      title: data?.getInvestigationDraftById?.title,
      description: investigationDescription,
    };

    editInvestigation({
      variables: {
        investigationDraft,
        imageFile: previewImage,
        standardsFile: standardsPDF,
        suppliesFile: suppliesPDF,
        letterHomeFile: letterHomePDF,
      },
    });
  }, [
    editInvestigation,
    investigationDescription,
    previewImage,
    standardsPDF,
    investigationId,
    data,
    suppliesPDF,
    letterHomePDF,
  ]);

  const setStagesDebounced = debounce(setStages, 300);
  const setInvestigationDescriptionDebounced = debounce(setInvestigationDescription, 200);

  return (
    <Layout>
      <PreviewSettingsSider
        setInvestigationDescription={setInvestigationDescriptionDebounced}
        investigationDescription={investigationDescription}
        setPreviewImage={setPreviewImage}
        setStandardsPDF={setStandardsPDF}
        setSuppliesPDF={setSuppliesPDF}
        setLetterHomePDF={setLetterHomePDF}
        updateStages={setStagesDebounced}
        previewImage={previewImage}
        standardsPDF={standardsPDF}
        suppliesPDF={suppliesPDF}
        letterHomePDF={letterHomePDF}
        stages={stages}
        loadingEditInvestigation={loadingEditInvestigation || loadingEditInvestigationSteps}
        onSaveDraft={onSaveDraft}
        imageFromUrl={data?.getInvestigationDraftById?.imageUrl}
        pdfFromUrl={data?.getInvestigationDraftById?.standardsUrl}
        letterHomePdfFromUrl={data?.getInvestigationDraftById?.letterHomeUrl}
      />
      <InvestigationContent>
        <Row justify="center" style={{ height: 'calc(100vh - 80px - 8em)' }}>
          <Col xs={23} md={23} lg={23} xxl={20}>
            {loading ? (
              <S.Skeleton>
                <InvestigationBookLoading />
              </S.Skeleton>
            ) : (
              <InvestigationBook
                stages={stages || []}
                investigationName={data?.getInvestigationDraftById?.title}
                discipline={data?.getInvestigationDraftById?.discipline?.name}
                subject={data?.getInvestigationDraftById?.discipline?.subject}
                investigationDescription={investigationDescription}
                imageSrc={previewImage ? URL.createObjectURL(previewImage) : data?.getInvestigationDraftById?.imageUrl}
                standardsUrl={data?.getInvestigationDraftById?.standardsUrl}
                suppliesUrl={data?.getInvestigationDraftById?.suppliesUrl}
                letterHomeUrl={data?.getInvestigationDraftById?.letterHomeUrl}
              />
            )}
          </Col>
        </Row>
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(AdiEditInvestigationPreviewPage);
