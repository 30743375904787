import { useMutation, useQuery } from '@apollo/client';
import { Col, Dropdown, Menu, message, Popconfirm, Row, Table, Tooltip } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import Papa from 'papaparse';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiDownload, FiSearch } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import Switch from '../../../shared/Switch';
import TableSearchBox from '../../../shared/TableSearchBox';
import { GQL_UserDetailsResponse } from '../../../types/profile';
import { centerAlign, Breakpoint } from '../../../utils/antd';
import { formatDateTime } from '../../../utils/date';
import { downloadCsv } from '../../../utils/files';
import { themeConfig } from '../../../utils/theme';
import TeacherAssistant from '../../TeacherProfilePage/TeacherAssistant';
import * as S from './styles';
import { SubscriptionPackageType } from '../../../constants/enums/SubscriptionPackageType';

const TableBreakPoint: Breakpoint[] = ['lg'];
const Current = () => {
  const ref = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [emailSearchVisible, setEmailSearchVisible] = useState(false);
  const [assignAssistantVisible, setAssignAssistantVisible] = useState(false);
  const { user, subscriptionPackageType } = useAuth();

  const { data, loading, refetch } = useQuery<{ getMyAssistants: GQL_UserDetailsResponse[] }>(
    gqlSchema.TeacherSchema.queries.ASSISTANT.getMyAssistants,
    {
      onError: (err) => {
        message.error('There was an error loading your assistants: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const exportAssistants = useCallback(() => {
    const headers = [['First Name', 'Last Name', 'Email']];
    const csvData =
      data?.getMyAssistants.map((assistant: GQL_UserDetailsResponse) => {
        return [assistant.firstName, assistant.lastName, assistant.email];
      }) || [];

    downloadCsv(Papa.unparse([...headers, ...csvData]), `Assistants.csv`);
  }, [data]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportAssistants}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportAssistants],
  );

  const [submitRemoveMyTeacherAssistant] = useMutation(
    gqlSchema.TeacherSchema.mutations.ASSISTANT.removeMyTeacherAssistant,
    {
      onCompleted: ({ removeMyTeacherAssistant }: { removeMyTeacherAssistant: string }) => {
        if (removeMyTeacherAssistant) {
          refetch();
          message.success(`Teacher Assistant removed successfully`);
        } else message.error(`Error removing Teacher Assistant`);
      },
      onError: (err) => {
        message.error('There was an error removing assistant: ' + err.message || 'Unexpected Error');
      },
    },
  );
  const [submitToggleAssistantGradePermission] = useMutation(
    gqlSchema.TeacherSchema.mutations.ASSISTANT.toggleAssistantGradePermission,
    {
      onCompleted: ({ toggleTAGradingPermission }: { toggleTAGradingPermission: string }) => {
        refetch();
        if (toggleTAGradingPermission.match('Permission added!')) {
          message.success(`${toggleTAGradingPermission}`);
        } else {
          message.warning(`${toggleTAGradingPermission}`);
        }
      },
      onError: (err) => {
        message.error(err.message || 'Unexpected Error');
      },
    },
  );
  const [submitToggleAssistantChatPermission] = useMutation(
    gqlSchema.TeacherSchema.mutations.ASSISTANT.toggleAssistantChatPermission,
    {
      onCompleted: ({ toggleTAChatPermission }: { toggleTAChatPermission: string }) => {
        refetch();
        if (toggleTAChatPermission.match('Permission added!')) {
          message.success(`${toggleTAChatPermission}`);
        } else {
          message.warning(`${toggleTAChatPermission}`);
        }
      },
      onError: (err) => {
        message.error(err.message || 'Unexpected Error');
      },
    },
  );
  const columns = useMemo(
    () => [
      {
        title: 'Assistant Name',
        sorter: (a: GQL_UserDetailsResponse, b: GQL_UserDetailsResponse) =>
          `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return (
            <span role="none" >
              {record.firstName} {record.lastName}
            </span>
          );
        },
        width: '20%',
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={searchVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_UserDetailsResponse) => {
          if (!value) return true;
          return (
            record.firstName.toLowerCase().includes(value.toString().toLowerCase()) ||
            record.lastName.toLowerCase().includes(value.toString().toLowerCase())
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (ref && ref.current) {
                ref.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Assistant Email',
        align: centerAlign,
        dataIndex: 'email',
        width: '20%',
        responsive: TableBreakPoint,
        sorter: (a: GQL_UserDetailsResponse, b: GQL_UserDetailsResponse) => a.email.localeCompare(b.email),
        filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={emailRef} {...filterProps} />,
        filterIcon: (filtered: boolean) => (
          <S.SearchIcon $searchVisible={emailSearchVisible}>
            <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
          </S.SearchIcon>
        ),
        onFilter: (value: string | number | boolean, record: GQL_UserDetailsResponse) => {
          if (!value) return true;
          return record.email.toLowerCase().includes(value.toString().toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setEmailSearchVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (emailRef && emailRef.current) {
                emailRef.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'Last Login',
        align: centerAlign,
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return formatDateTime(record.lastLogin, 'MM.dd.yyyy HH:mm:ss');
        },
        width: '15%',
      },
      {
        title: 'Allowed to grade',
        align: centerAlign,
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return (
            <Tooltip
              title={`This allows assistants to grade students final reports${
                !user.subscription?.taCustomPermissions
                  ? ' and can be enabled by upgrading your subscription. Talk to your ADI admin for further details'
                  : ''
              }.`}
            >
              <S.CentralizedDiv>
                <Switch
                  titleAlign="right"
                  type="text"
                  title=""
                  value={record.isAllowedGrading}
                  onChange={(e: boolean) =>
                    submitToggleAssistantGradePermission({
                      variables: {
                        data: { teacherId: user.id, assistantId: record.id, isAllowedGrading: e },
                      },
                    })
                  }
                />
              </S.CentralizedDiv>
            </Tooltip>
          );
        },
        width: '15%',
      },
      {
        title: 'Allowed to chat with students',
        align: centerAlign,
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return (
            <Tooltip
              title={`This allows assistants to chat with students${
                !user.subscription?.taCustomPermissions
                  ? ' and can be enabled by upgrading your subscription. Talk to your ADI admin for further details'
                  : ''
              }.`}
            >
              <S.CentralizedDiv>
                <Switch
                  title=""
                  titleAlign="right"
                  type="text"
                  value={record.isAllowedChat}
                  onChange={(e: boolean) =>
                    submitToggleAssistantChatPermission({
                      variables: {
                        data: { teacherId: user.id, assistantId: record.id, isAllowedChat: e },
                      },
                    })
                  }
                />
              </S.CentralizedDiv>
            </Tooltip>
          );
        },
        width: '15%',
      },
      {
        title: 'Actions',
        align: centerAlign,
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return (
            <Popconfirm
              placement="topRight"
              title={
                <div style={{ textAlign: 'center' }}>
                  <S.Info>
                    Remove {record.firstName} {record.lastName}?
                  </S.Info>
                </div>
              }
              onConfirm={() => {
                submitRemoveMyTeacherAssistant({ variables: { assistantId: record.id } });
              }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button text="Remove Assistant" onClick={() => {}} block />
            </Popconfirm>
          );
        },
        width: '10%',
      },
    ],
    [
      emailSearchVisible,
      searchVisible,
      submitRemoveMyTeacherAssistant,
      submitToggleAssistantGradePermission,
      submitToggleAssistantChatPermission,
      user,
    ],
  );

  return (
    <>
      <Row justify="space-between" gutter={[24, 24]}>
        <Col xxl={{ span: 3 }} xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 12 }}>
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              padding="4px 5px"
              style={{ minWidth: '200px' }}
              text={
                <>
                  Export as
                  <FiChevronDown style={{ marginLeft: 3 }} />
                </>
              }
              theme={themeConfig.noColor}
              icon={<FiDownload />}
              block
            />
          </Dropdown>
        </Col>
        <Col>
          <Button
            disabled={subscriptionPackageType === SubscriptionPackageType.STARTER}
            text="Add Teacher Assistant"
            style={{ float: 'right', minWidth: '200px' }}
            theme={themeConfig.primaryColor}
            onClick={() => setAssignAssistantVisible(true)}
            disabledReason={
              subscriptionPackageType === SubscriptionPackageType.STARTER ? (
                <>
                  Please upgrade to a <b>Pro</b> or <b>Premium</b> account to use this feature.
                </>
              ) : (
                <></>
              )
            }
          />
        </Col>
        <Col style={{ paddingTop: 0 }} span={24}>
          <S.TableWrapper>
            <Table
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_UserDetailsResponse) => record.id}
              bordered
              dataSource={data?.getMyAssistants}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <TeacherAssistant
        currentTeachers={data?.getMyAssistants.length || 0}
        onInvited={() => refetch()}
        visible={assignAssistantVisible}
        setVisible={setAssignAssistantVisible}
      />
    </>
  );
};

export default Current;
