import { Col, Divider, Checkbox } from 'antd';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';

export const Body = styled(Col)`
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
`;

export const Line = styled(Col)`
  min-height: 74.2px;

  .ant-form-item {
    margin: 0;
  }
`;

export const CDivider = styled(Divider)`
  margin: 15px 0;
`;

export const TitleInput = styled.div<{
  $padding?: string;
  $isTrial?: boolean;
}>`
  font-weight: 600;
  max-width: ${(props) => (props.$isTrial ? 'calc(100% - 50px)' : '100%')};
  padding: ${(props) => (props.$padding ? props.$padding : ' 0 11px')};
  font-size: 1.1em;
  margin: 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  display: flex;
  align-items: center;
  .ant-progress {
    flex: 1;
    margin-left: 16px;
  }
`;

export const SInput = styled(Input)<{
  $bold?: boolean;
}>`
  font-size: 0.95em;
  color: #4a4a4a !important;
  background-color: #ffffff;
  font-weight: ${(props) => (props.$bold ? '500' : 'unset')};
  .ant-input-suffix {
    display: none;
  }
`;

export const IdentitySourceInput = styled(SInput)`
  height: 40px;
`;

export const EditButton = styled(Button)`
  background: ${(props) => props.theme.primaryColor.background};
  border-color: ${(props) => props.theme.primaryColor.background};
  line-height: 0;
  display: inline;
  margin: 0 3px;
  min-height: 32px;
  svg {
    font-size: 20px;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${(props) => props.theme.primaryColor.background};
    border-color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const CancelButton = styled(EditButton)``;

export const SubText = styled.p`
  margin: 0;
  font-size: 15px;
  color: #767676 !important;
  height: 31px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 12px;
`;

export const CCheckbox = styled(Checkbox)<{
  $top: string;
  $float: string;
}>`
  margin-top: ${(props) => props.$top};
  float: ${(props) => props.$float};
`;

export const Ribbon = styled.h4`
  margin: 0;
  padding: 0;
  background: ${(props) => props.theme.primaryColor.background};
  color: white;
  padding: 0.5em 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px; /* tweak */
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.primaryColor.background};
  }
  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
`;

export const TitleTab = styled.p`
  font-weight: 700;
  padding: 0;
  font-size: 1.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;
