// same as in adi-backend/source/enums/Role.ts
export enum Role {
  STUDENT = 'student',
  TEACHER = 'teacher',
  TEACHER_ASSISTANT = 'teacher_assistant',
  ORGANIZATION_ADMIN = 'organization_admin',
  ADI_ADMIN = 'adi_admin',
  ADI_SUPER_ADMIN = 'adi_super_admin',
  WRITER = 'writer',
  FACILITATOR = 'facilitator',
  ADVANCED_SEARCH = 'advanced_search',
  NON_ENTERPRISE = 'non_enterprise',
  GOOGLE_TEACHER='google_teacher',
  GOOGLE_STUDENT='google_student',
  CANVAS_TEACHER='canvas_teacher',
  CANVAS_STUDENT='canvas_student',

}
