import { useLazyQuery } from '@apollo/client';
import React, { createContext, useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../gql/schema';
import client from '../gql/api';
import { GQL_MeResponse } from '../types/login';
import { message } from 'antd';
import { ImmersiveReaderTokenResponse } from './useImmersiveReader';
import useRoleSelectionModal from './useRoleSelectionModal';
import { SUBJECT_TYPES } from '../types/subjects';
import { Role } from '../constants/enums/Role';

interface AuthState {
  user: GQL_MeResponse;
  immersiveReaderToken?: ImmersiveReaderTokenResponse;
  isLogged: boolean;
  isStudent: boolean;
  isTeacher: boolean;
  isTeacherAssistant: boolean;
  isAdiSuperAdmin: boolean;
  isAdiAdmin: boolean;
  isOrganizationAdiAdmin: boolean;
  isSubscriptionUser: boolean;
  isWriter: boolean;
  isFacilitator: boolean;
  isTeacherOrFacilitator: boolean;
  isAdvancedSearch: boolean;
  subscriptionPackageType: string;
  isUserDisabled?: boolean;
  isUserSuspended?: boolean;
  isTASubscriptionUser: boolean;
  isDowngradePending: boolean;
  dismissedInsightsTutorial: boolean;
  googleAuthToken?: string | null;
  googleAuthRefreshToken?:string|null;
  isGoogleTeacher: boolean;
  isGoogleStudent: boolean;
  isCanvasTeacher:boolean;
  isCanvasStudent:boolean;
  canvasAccessToken?: string | null;
  canvasAccessRefreshToken?: string | null;
  
}

interface AuthContextData {
  setSignedIn(payload: { token: string; user: GQL_MeResponse }): void;
  signOut(): void;
  signOutAdvanced(version: string): void;
  updateUser(user: Partial<GQL_MeResponse>): void;
  updateImmersiveReaderToken(payload: ImmersiveReaderTokenResponse): void;
  activeRole?: string;
  setActiveRole(role: string): void;
  // activeSubscription?: string;
  // setActiveSubscription(subscription: string): void;
  immersiveReaderToken?: ImmersiveReaderTokenResponse;
  isLogged: boolean;
  user: GQL_MeResponse;
  isStudent: boolean;
  isTeacher: boolean;
  isTeacherAssistant: boolean;
  isAdiSuperAdmin: boolean;
  isAdiAdmin: boolean;
  isOrganizationAdiAdmin: boolean;
  isSubscriptionUser: boolean;
  isWriter: boolean;
  isFacilitator: boolean;
  isTeacherOrFacilitator: boolean;
  isScienceTexasEditionEnabledWithGrades: boolean | undefined;
  isAdvancedSearch: boolean;
  isActiveAsAdmin: boolean;
  subscriptionPackageType: string;
  isUserDisabled?: boolean;
  isUserSuspended?: boolean;
  belongsToOrganization?: boolean;
  fetchProfile?(): void;
  isTASubscriptionUser: boolean;
  isDowngradePending: boolean;
  dismissedInsightsTutorial: boolean;
  googleAuthToken?: string | null;
  isGoogleTeacher: boolean;
  isGoogleStudent: boolean;
  isCanvasTeacher:boolean;
  isCanvasStudent:boolean;
  canvasAccessToken?: string | null;
  canvasAccessRefreshToken?: string | null;
  googleAuthRefreshToken?:string|null;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
export const AUTH_TOKEN_KEY = '@ADI:token';
export const GOOGLE_AUTH_TOKEN_KEY = '@ADI:Googletoken';
export const GOOGLE_REFRESH_TOKEN_KEY = '@ADI:refresh-token-Google';
export const GOOGLE_EXPIRATION_TOKEN_KEY = '@ADI:expiration-token-Google';
export const CANVAS_AUTH_TOKEN_KEY = '@ADI:Canvastoken';
export const CANVAS_REFRESH_TOKEN_KEY = '@ADI:refresh-token-Canvas';
export const CANVAS_EXPIRATION_TOKEN_KEY = '@ADI:expiration-token-canvas';
export const TOKEN_EXPIRATION_KEY = '@ADI:token-expiration';
export const REFRESH_TOKEN_KEY = '@ADI:refresh-token';
export const IMPERSONATING = '@ADI:impersonating';
const USER_KEY = '@ADI:user';
const READER_TOKEN_KEY = '@ADI:reader-token';
const EPOCH_TIME_LENGTH = 10;
export const getExpirationTokenCasted = (expiresIn: string | undefined) =>
  expiresIn?.length !== EPOCH_TIME_LENGTH
    ? new Date(Date.now()).setSeconds(Number(expiresIn))
    : Date.now() + (Number(expiresIn) * 1000 - Date.now()) / 2;

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [activeRole, setActiveRole] = useState<string | undefined>();
  // const [activeSubscription, setActiveSubscription] = useState<string | undefined>();

  const [fetchProfile] = useLazyQuery(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me, {
    onCompleted: ({ me }: { me: GQL_MeResponse }) => {

      updateUser(me);
    },
    onError: (err) => {
      message.error('There was an error loading your data: ' + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'cache-and-network',
  });

  const spreadRoles = useCallback(
    (user?: GQL_MeResponse, activeRole?: string) => {
      if (!user)
        return {
          isStudent: false,
          isTeacher: false,
          isTeacherAssistant: false,
          isAdiSuperAdmin: false,
          isAdiAdmin: false,
          isOrganizationAdiAdmin: false,
          isSubscriptionUser: false,
          isWriter: false,
          isFacilitator: false,
          isTeacherOrFacilitator: false,
          isAdvancedSearch: false,
          subscriptionPackageType: 'None',
          isTASubscriptionUser: false,
          isDowngradePending: false,
          dismissedInsightsTutorial: false,
          isGoogleTeacher: false,
          isGoogleStudent: false,
          isCanvasTeacher: false,
          isCanvasStudent: false,
          
        };

      const isTeacher = user.roles.includes('teacher') && activeRole === 'teacher';
      const isStudent = user.roles.includes('student') && activeRole === 'student';
      const isTeacherAssistant = user.roles.includes('teacher_assistant') && activeRole === 'teacher_assistant';
      const isAdiSuperAdmin = user.roles.includes('adi_super_admin') && activeRole === 'adi_super_admin';
      const isAdiAdmin = user.roles.includes('adi_admin') && activeRole === 'adi_admin';
      const isOrganizationAdiAdmin = user.roles.includes('organization_admin') && activeRole === 'organization_admin';
      const isSubscriptionUser =
        user.roles.includes('teacher') && user.roles.includes('non_enterprise') && activeRole === 'teacher' && user?.subscription?.organizationId === null;
      const subscriptionPackageType =
        isSubscriptionUser && user.stripeSubscription?.type ? user.stripeSubscription.type : '';
      const isDowngradePending = isSubscriptionUser && !!user.stripeSubscription?.isDowngradePending;
      const isWriter = user.roles.includes('writer');
      const isFacilitator = user.roles.includes('facilitator') && activeRole === 'facilitator';
      const isTeacherOrFacilitator = isTeacher || isFacilitator;
      const isAdvancedSearch = !!user?.isAdvancedSearch;
      const isUserDisabled = user?.disabled;
      const isUserSuspended = user?.suspended;
      const isTASubscriptionUser = isTeacherAssistant && !!user.stripeSubscription;
      const dismissedInsightsTutorial = !!user.dismissedInsightsTutorial;
      const isGoogleStudent = user.roles.includes('google_student') && activeRole === 'google_student';
      const isGoogleTeacher = user.roles.includes('google_teacher') && activeRole === 'google_teacher';
      const isCanvasStudent = user.roles.includes('canvas_student') && activeRole === 'canvas_student';
      const isCanvasTeacher = user.roles.includes('canvas_teacher') && activeRole === 'canvas_teacher';


      return {
        isStudent,
        isTeacher,
        isAdiSuperAdmin,
        isAdiAdmin,
        isTeacherAssistant,
        isOrganizationAdiAdmin,
        isSubscriptionUser,
        isWriter,
        isFacilitator,
        isTeacherOrFacilitator,
        isAdvancedSearch,
        subscriptionPackageType,
        isUserDisabled,
        isUserSuspended,
        fetchProfile,
        isTASubscriptionUser,
        isDowngradePending,
        dismissedInsightsTutorial,
        isGoogleTeacher,
        isGoogleStudent,
        isCanvasTeacher,
        isCanvasStudent,


      };
    },
    [fetchProfile],
  );

  const [data, setData] = useState<AuthState>(() => {
    let token = sessionStorage.getItem(AUTH_TOKEN_KEY);
    let user = sessionStorage.getItem(USER_KEY);
    let immersiveToken = sessionStorage.getItem(READER_TOKEN_KEY);
    let canvasToken = sessionStorage.getItem(CANVAS_AUTH_TOKEN_KEY);
    let googleToken=sessionStorage.getItem(GOOGLE_AUTH_TOKEN_KEY);
    let googleRefreshToken=sessionStorage.getItem(GOOGLE_REFRESH_TOKEN_KEY);
    let canvasRefreshToken=sessionStorage.getItem(CANVAS_REFRESH_TOKEN_KEY);


    if (!token && !user) {
      user = localStorage.getItem(USER_KEY);
      token = localStorage.getItem(AUTH_TOKEN_KEY);
      immersiveToken = localStorage.getItem(READER_TOKEN_KEY);
      googleToken = localStorage.getItem(GOOGLE_AUTH_TOKEN_KEY);
      canvasToken = localStorage.getItem(CANVAS_AUTH_TOKEN_KEY);
    }

    if (token && user) {
      fetchProfile();
      const userObj = JSON.parse(user);
      const immersiveTokenObj = immersiveToken ? JSON.parse(immersiveToken) : undefined;

      const userPreferredRole = userObj.preferredRole;
      const userHasPreferredRole = userPreferredRole && userObj.roles?.includes(userPreferredRole);
      let newActiveRole = userHasPreferredRole ? userPreferredRole : userObj.roles[0];
      if (userObj.roles?.length === 1) {
        setActiveRole(newActiveRole);
      } else if (userObj.roles?.length > 1) {
        setActiveRole(newActiveRole);
      }
      // if (newActiveRole === Role.ORGANIZATION_ADMIN) {
      //   setActiveSubscription("organization");
      // } else if (newActiveRole === Role.ADI_ADMIN || newActiveRole === Role.ADI_SUPER_ADMIN) {
      //   setActiveSubscription("user");
      // } else {
      //   if (userObj?.subscriptions?.length !== undefined && userObj.subscriptions.length > 1) {
      //     let hasOrgSubscription = false;
      //     userObj.subscriptions.forEach((subscription:any) => {
      //       if (subscription?.organizationId && subscription.organizationId !== undefined && subscription.organizationId !== null) {
      //         hasOrgSubscription = true;
      //       }
      //     });
      //     if (hasOrgSubscription) {
      //       setActiveSubscription("organization");
      //     } else {
      //       setActiveSubscription("user");
      //     }
      //   }
      // }
      return {
        token,
        googleAuthToken: googleToken,
        canvasAccessToken: canvasToken,
        canvasAccessRefreshToken: canvasRefreshToken,
       googleAuthRefreshToken:googleRefreshToken,
        user: userObj,
        immersiveReaderToken: immersiveTokenObj,
        isLogged: true,
        ...spreadRoles(userObj, newActiveRole),
      };
    }

    return { isLogged: false, ...spreadRoles() } as AuthState;
  });

  const updateUser = useCallback(
    (user: Partial<GQL_MeResponse>) => {
      setData((data) => {
        const newUser = { ...data.user, ...user };
        sessionStorage.setItem(USER_KEY, JSON.stringify(newUser));
        let googleToken = sessionStorage.getItem(GOOGLE_AUTH_TOKEN_KEY);

        let canvasToken = sessionStorage.getItem(CANVAS_AUTH_TOKEN_KEY);


        // set active role
        const userPreferredRole = user.preferredRole;
        const userHasPreferredRole = userPreferredRole && user.roles?.includes(userPreferredRole);
        let newActiveRole = userHasPreferredRole ? userPreferredRole : user.roles ? user.roles[0] : '';
        if (user.roles?.length === 1) {
          setActiveRole(newActiveRole);
        } else if (user.roles !== undefined && user.roles.length > 1) {
          setActiveRole(newActiveRole);
        }

        // if (newActiveRole === Role.ORGANIZATION_ADMIN) {
        //   setActiveSubscription("organization");
        // } else if (newActiveRole === Role.ADI_ADMIN || newActiveRole === Role.ADI_SUPER_ADMIN) {
        //   setActiveSubscription("user");
        // } else {
        //   if (user?.subscriptions?.length !== undefined && user.subscriptions.length > 1) {
        //     let hasOrgSubscription = false;
        //     user.subscriptions.forEach((subscription:any) => {
        //       if (subscription?.organizationId && subscription.organizationId !== undefined && subscription.organizationId !== null) {
        //         hasOrgSubscription = true;
        //       }
        //     });
        //     if (hasOrgSubscription) {
        //       setActiveSubscription("organization");
        //     } else {
        //       setActiveSubscription("user");
        //     }
        //   }
        // }
        return { ...data, googleAuthToken: googleToken, canvasAccessToken: canvasToken, user: newUser, ...spreadRoles(newUser, newActiveRole) };
      });
    },
    [spreadRoles],
  );

  const handleActiveRoleChange = useCallback(
    (role: string) => {
      setActiveRole(role);
      // if (role === Role.ORGANIZATION_ADMIN) {
      //   setActiveSubscription("organization");
      // } else if (role === Role.ADI_ADMIN || role === Role.ADI_SUPER_ADMIN) {
      //   setActiveSubscription("user");
      // } else {
      //   if (data?.user?.subscriptions?.length !== undefined && data?.user.subscriptions.length > 1) {
      //     let hasOrgSubscription = false;
      //     data.user.subscriptions.forEach((subscription:any) => {
      //       if (subscription?.organizationId && subscription.organizationId !== undefined && subscription.organizationId !== null) {
      //         hasOrgSubscription = true;
      //       }
      //     });
      //     if (hasOrgSubscription) {
      //       setActiveSubscription("organization");
      //     } else {
      //       setActiveSubscription("user");
      //     }
      //   }
      // }
      setData((data) => ({ ...data, ...spreadRoles(data.user, role) }));
      updateUser({
        preferredRole: role,
        subscription: data?.user?.subscriptions?.find((s) =>
          role === 'facilitator' ? s.type === 'facilitator' : s.type === 'standard',
        ),
      });

      // redirect the page to home route with refresh
      window.location.href = '/';
    },
    [spreadRoles, updateUser, data],
  );

  const { openModal } = useRoleSelectionModal({ setActiveRole: handleActiveRoleChange });

  const setSignedIn = useCallback(
    (payload: { token: string; user: GQL_MeResponse }) => {
      const { token, user } = payload;
      const expiration = getExpirationTokenCasted(user?.expiresIn);
      sessionStorage.setItem(GOOGLE_AUTH_TOKEN_KEY, user.googleAuthToken || '')
      sessionStorage.setItem(GOOGLE_REFRESH_TOKEN_KEY, user.googleAuthRefreshToken || '');
      sessionStorage.setItem(CANVAS_AUTH_TOKEN_KEY, user.canvasAccessToken || '')
      sessionStorage.setItem(CANVAS_REFRESH_TOKEN_KEY, user.canvasRefreshToken || '');
      sessionStorage.setItem(AUTH_TOKEN_KEY, token);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, user.refreshToken);
      sessionStorage.setItem(TOKEN_EXPIRATION_KEY, String(expiration));
      sessionStorage.setItem(USER_KEY, JSON.stringify(user));
      sessionStorage.setItem(IMPERSONATING, String(false));

      if (user.isAdvancedSearch) {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        localStorage.setItem(REFRESH_TOKEN_KEY, user.refreshToken);
        localStorage.setItem(TOKEN_EXPIRATION_KEY, String(expiration));
        localStorage.setItem(USER_KEY, JSON.stringify(user));
      }

      const userPreferredRole = user.preferredRole;
      const userHasPreferredRole = userPreferredRole && user.roles?.includes(userPreferredRole);
      let newActiveRole = userHasPreferredRole ? userPreferredRole : user.roles[0];
      if (user.roles?.length === 1) {
        setActiveRole(newActiveRole);
      } else if (user.roles?.length > 1) {
        // when we have support to all roles we can drop everything besides user not having preferred role
        const supportedRoles = [
          'teacher',
          'student',
          'organization_admin',
          'facilitator',
          'adi_admin',
          'adi_super_admin',
          'teacher_assistant',
          'google_teacher',
          'google_student',
          'canvas_teacher',
          'canvas_student',
        ];
        const hasTwoOrMoreSupportedRoles = user.roles.filter((role) => supportedRoles.includes(role)).length > 1;
        if (!user.preferredRole && hasTwoOrMoreSupportedRoles) {
          openModal({ user });
        }
        setActiveRole(newActiveRole);
      }

      setData({ ...data, user, isLogged: true, ...spreadRoles(user, newActiveRole) });
    },
    [data, spreadRoles, openModal],
  );

  const handleSignOut = useCallback(
    (path = '/') => {
      sessionStorage.removeItem(AUTH_TOKEN_KEY);
      sessionStorage.removeItem(REFRESH_TOKEN_KEY);
      sessionStorage.removeItem(GOOGLE_AUTH_TOKEN_KEY);
      sessionStorage.removeItem(GOOGLE_REFRESH_TOKEN_KEY);
      sessionStorage.removeItem(CANVAS_AUTH_TOKEN_KEY);
      sessionStorage.removeItem(CANVAS_REFRESH_TOKEN_KEY);
      sessionStorage.removeItem(TOKEN_EXPIRATION_KEY);
      sessionStorage.removeItem(USER_KEY);
      sessionStorage.removeItem(READER_TOKEN_KEY);
      sessionStorage.removeItem(IMPERSONATING);
      localStorage.removeItem(AUTH_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.removeItem(TOKEN_EXPIRATION_KEY);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(READER_TOKEN_KEY);
      client.clearStore();

      history.push(path);

      setData({ isLogged: false, ...spreadRoles() } as AuthState);
    },
    [history, spreadRoles],
  );

  const signOut = useCallback(() => {
    handleSignOut();
  }, [handleSignOut]);

  const signOutAdvanced = useCallback(
    (version: string) => {
      handleSignOut(`/advanced-search/${version}/login`);
    },
    [handleSignOut],
  );

  const updateImmersiveReaderToken = useCallback(
    (payload: ImmersiveReaderTokenResponse) => {
      sessionStorage.setItem(READER_TOKEN_KEY, JSON.stringify(payload));
      setData({ ...data, immersiveReaderToken: payload });
    },
    [data],
  );

  useEffect(() => {
    const isImpersonating = sessionStorage.getItem(IMPERSONATING) === String(true);
    if (isImpersonating) {
      sessionStorage.setItem(IMPERSONATING, String(false));

      const url = new URL(window.location.origin);
      url.searchParams.set('reloadTime', Date.now().toString());
      window.location.href = url.toString();
    }
  }, []);

  const isScienceTexasEditionEnabledWithGrades = useMemo(() => {
    const areScienceTexasEditionGradesEnabled = data.user?.subscription?.allowedGradeBands?.some(
      (gradeBand) => !gradeBand.includes('-'),
    );
    const isScienceTexasEditionSubjectEnabled = data.user?.subscription?.allowedSubjects?.includes(
      SUBJECT_TYPES.SCIENCE_TEXAS_EDITION,
    );
    return areScienceTexasEditionGradesEnabled && isScienceTexasEditionSubjectEnabled;
  }, [data]);

  const isActiveAsAdmin = useMemo(() => {
    return activeRole === Role.ADI_ADMIN || activeRole === Role.ADI_SUPER_ADMIN;
  }, [activeRole]);

  return (
    <AuthContext.Provider
      value={{
        setSignedIn,
        signOut,
        signOutAdvanced,
        updateUser,
        updateImmersiveReaderToken,
        activeRole,
        setActiveRole: handleActiveRoleChange,
        // activeSubscription,
        // setActiveSubscription,
        isActiveAsAdmin,
        immersiveReaderToken: data.immersiveReaderToken,
        isLogged: data.isLogged,
        user: data.user,
        isStudent: data.isStudent,
        isTeacher: data.isTeacher,
        isTeacherAssistant: data.isTeacherAssistant,
        isAdiSuperAdmin: data.isAdiSuperAdmin,
        isAdiAdmin: data.isAdiAdmin,
        isOrganizationAdiAdmin: data.isOrganizationAdiAdmin,
        isSubscriptionUser: data.isSubscriptionUser,
        isWriter: data.isWriter,
        isFacilitator: data.isFacilitator,
        isTeacherOrFacilitator: data.isTeacher || data.isFacilitator,
        isAdvancedSearch: data.isAdvancedSearch,
        isScienceTexasEditionEnabledWithGrades: isScienceTexasEditionEnabledWithGrades,
        subscriptionPackageType: data.subscriptionPackageType,
        isUserDisabled: data?.user?.disabled,
        fetchProfile: fetchProfile,
        isUserSuspended: data?.user?.suspended,
        belongsToOrganization: Boolean(data?.user?.organizationName),
        isTASubscriptionUser: data.isTASubscriptionUser,
        isDowngradePending: data.isDowngradePending,
        dismissedInsightsTutorial: data.dismissedInsightsTutorial,
        googleAuthToken: data.googleAuthToken,
        isGoogleStudent: data.isGoogleStudent,
        isGoogleTeacher: data.isGoogleTeacher,
        googleAuthRefreshToken:data.googleAuthRefreshToken,
        isCanvasStudent: data.isCanvasStudent,
        isCanvasTeacher: data.isCanvasTeacher,
        canvasAccessToken: data.canvasAccessToken,
        canvasAccessRefreshToken:data.canvasAccessToken
        
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };