import { gql } from '@apollo/client';

const INVITES = {
  SEND: {
    sendInvites: gql`
      mutation SendInvites($data: SendInvitesInput!) {
        sendInvites(data: $data) {
          emailSent
          token
        }
      }
    `,
    createAndSendInvite: gql`
      mutation CreateAndSendInvite($data: CreateInviteInput!) {
        createAndSendInvite(data: $data) {
          emailSent
          token
        }
      }
    `,
    createAndSendInvites: gql`
      mutation CreateAndSendInvites($data: [CreateInviteInput]!) {
        createAndSendInvites(data: $data) {
          emailSent
          token
        }
      }
    `,
    inviteFreelanceTeacher: gql`
      mutation InviteFreelanceTeacher($data: InviteFreelanceTeacherInput!) {
        inviteFreelanceTeacher(data: $data) {
          emailSent
          token
        }
      }
    `,
    inviteMultipleFreelanceTeachers: gql`
      mutation InviteMultipleFreelanceTeachers($data: [InviteFreelanceTeacherInput!]!, $acceptExistingUsers: Boolean) {
        inviteMultipleFreelanceTeachers(data: $data, acceptExistingUsers: $acceptExistingUsers) {
          emailSent
          token
          invalid
          reason
        }
      }
    `,
    inviteOrganization: gql`
      mutation InviteOrganization($data: InviteOrganizationInput!) {
        inviteOrganization(data: $data) {
          emailSent
          token
        }
      }
    `,
  },
};

export default INVITES;
