import { gql } from '@apollo/client';
import ORGANIZATION_FRAGMENTS from '../fragment/Organization';

const ORGANIZATION_QUERIES = {
  LIST: {
    getAllOrganizations: gql`
      query GetAllOrganizations {
        getAllOrganizations {
          id
          name
          defaultTAQuantity
          organizationAdmins {
            ...NewOrganizationAdmin
          }
        }
      }
      ${ORGANIZATION_FRAGMENTS.newOrganizationAdmin}
    `,
  },
  DETAILS: {
    getOrganizationDetails: gql`
      query GetOrganizationDetails($id: String!) {
        getOrganizationDetails(id: $id) {
          id
          name
          defaultTAQuantity
          blockAdiInvestigations
          classLinkOrgId
          cleverOrgId
          organizationAdmins {
            ...NewOrganizationAdmin
          }
          subscription {
            id
            userId
            organizationId
            invitesAvailable
            startDate
            endDate
            isTrial
            customLibrary
            investigationLibraryLimit
            writerQuantity
            taCustomPermissions
            readerPermissions
            reportPermission
            teachersCanDoReviews
            allowedSubjects
            allowedGradeBands
          }
          allowedStates {
            id
            name
            postalCode
          }
        }
      }
      ${ORGANIZATION_FRAGMENTS.newOrganizationAdmin}
    `,
  },
  EXPORT: {
    exportTeacherData: gql`
      query {
        exportTeacherData
      }
    `,
    exportStudentData: gql`
      query {
        exportStudentData
      }
    `,
    exportDataScoreAverage: gql`
      query exportDataScoreAverage($data: QueryRawScoreAverageInput) {
        exportDataScoreAverage(data: $data) {
          teacherId
          teacherFirstName
          teacherLastName
          teacherEmail
          tags
          investigations {
            id
            title
            gradeBand
            discipline
            subject
            startDate
            endDate
            totalNumberOfStudents
            numberOfGradedStudents
            classId
            className
            avgReportScore
            avgEngagementScore
            avgFeedbackScore
          }
        }
      }
    `,
  },
};

export default ORGANIZATION_QUERIES;
