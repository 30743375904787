import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm, Row, message, Typography, Col, Form, Modal, Select } from 'antd';

import Input from '../../shared/Input';
import * as S from './styles';
import { ILoginProps } from '../../types';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_LoginResponse, Organization } from '../../types/login';
import { useAuth } from '../../hooks/useAuth';
import client from '../../gql/api';
import cleverC from '../../assets/cleverC.png';
import googleClassroom from '../../assets/google-classroom.svg';
import classLinkLogoSrc from '../../assets/classlink.png';
import googleLogoSrc from '../../assets/google.png';
import adiLearningHubSrc from '../../assets/adi-learning-hub.svg';
import useRoleUpdater from '../../hooks/useRoleUpdater';
import canvas from '../../assets/canvas.png';
import Button from '../../shared/Button';



type Props = RouteComponentProps<null, any, any>;

const LoginPage = (props: Props) => {
  const history = useHistory();
  const { setSignedIn } = useAuth();
  const queryStringParams = props.location.search;
  const queryStringParamsObj = new URLSearchParams(queryStringParams);
  const code = queryStringParamsObj.get('code');
  const provider = queryStringParamsObj.get('provider');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { Option } = Select;


  const { data: orgData, loading: loadingOrganizations, error: orgError } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasOrganizationList
  );
  const organizationList = orgData?.getCanvasOrganizationList?.map((org: any) => org) || [];
  console.log("OrgData", orgData);

  const handleLoginWithCanvas = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { updatePreferredRoleToGoogleTeacher, updatePreferredRoleToGoogleStudent, updatePreferredRoleTeacher, updatePreferredRoleToCanvasStudent, updatePreferredRoleToCanvasTeacher, updatePreferredRoleToTeacher } = useRoleUpdater();

  const [submitLoginWithClever, { loading: loadingCleverSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithClever,
    {
      onCompleted: ({ loginWithClever }: { loginWithClever: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithClever;

        setSignedIn({
          token: loginWithClever.accessToken,
          user: { ...data, id: loginWithClever.id },
        });
        client.resetStore();

        if (data.roles.includes('student')) history.push('/student-dashboard');
        else history.push('/teacher-dashboard');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitLoginWithClassLink, { loading: loadingClassLinkSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithClasslink,
    {
      onCompleted: ({ loginWithClasslink }: { loginWithClasslink: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithClasslink;

        setSignedIn({
          token: loginWithClasslink.accessToken,
          user: { ...data, id: loginWithClasslink.id },
        });
        client.resetStore();

        if (data.roles.includes('student')) history.push('/student-dashboard');
        else history.push('/teacher-dashboard');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitLoginWithGoogle, { loading: loadingGoogleSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithGoogle,
    {
      onCompleted: ({ loginWithGoogle }: { loginWithGoogle: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithGoogle;

        setSignedIn({
          token: loginWithGoogle.accessToken,
          user: { ...data, id: loginWithGoogle.id },
        });
        client.resetStore();
        console.log("roles", data.roles);

        if (data.roles.includes('teacher')) {
          updatePreferredRoleTeacher()
        }
        else {
          history.push('/student-dashboard');
        }


      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );
  const [submitLoginWithGoogleTest, { loading: loadingGoogleSSOTest }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithGoogleTest,
    {
      onCompleted: ({ loginWithGoogleTest }: { loginWithGoogleTest: GQL_LoginResponse }) => {
        const { accessToken, googleAuthToken, googleAuthRefreshToken, ...data } = loginWithGoogleTest;

        console.log("token", googleAuthToken);

        setSignedIn({
          token: accessToken,
          user: { ...data, googleAuthToken, googleAuthRefreshToken },
        });

        if (loginWithGoogleTest.isGoogleTeacher) {
          updatePreferredRoleToGoogleTeacher();
          history.push('/googleclassroom-teacher-dashboard');
        }
        else {
          updatePreferredRoleToGoogleStudent();
          history.push('/googleclassroom-students-dashboard');
        }

        // if (data.roles.includes('student')) history.push('/student-dashboard');
        // else history.push('/teacher-dashboard');
        client.resetStore();
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  const [submitLoginWithCanvas, { loading: loadingCanvasSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithCanvas,
    {
      onCompleted: ({ loginWithCanvas }: { loginWithCanvas: GQL_LoginResponse }) => {
        const { accessToken, canvasAccessToken, canvasRefreshToken, ...data } = loginWithCanvas; // Extract canvasToken
        console.log("tookken", canvasAccessToken);

        setSignedIn({
          token: accessToken,
          user: { ...data, canvasAccessToken, canvasRefreshToken },

        });

        if (loginWithCanvas.isCanvasTeacher) {
          updatePreferredRoleToCanvasTeacher();
        }
        else {
          updatePreferredRoleToCanvasStudent();
        }
        client.resetStore();

      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  useEffect(() => {
    if (code) {
      if (provider === 'clever') {
        submitLoginWithClever({
          variables: {
            code,
          },
        });
      } else if (provider === 'googleTest') {
        submitLoginWithGoogleTest({
          variables: {
            code,
          },
        });
      } else if (provider === 'classlink') {
        submitLoginWithClassLink({
          variables: {
            code,
          },
        });
      } else if (provider === 'google') {
        submitLoginWithGoogle({
          variables: {
            code,
          },
        });
      } else if (provider === 'canvas' && localStorage.getItem('CANVAS_ORG_ID')) {
        submitLoginWithCanvas({
          variables: {
            code,
            canvasOrgId: localStorage.getItem('CANVAS_ORG_ID'),
          },
        });
      }
    }
  }, [code, provider, submitLoginWithClever, submitLoginWithClassLink, submitLoginWithGoogle, submitLoginWithGoogleTest, submitLoginWithCanvas]);

  const [submitLogin, { loading }] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.login, {
    onCompleted: ({ login }: { login: GQL_LoginResponse }) => {
      const { accessToken, ...data } = login;

      setSignedIn({
        token: login.accessToken,
        user: { ...data, id: login.id },
      });

      client.resetStore();

      if (data.roles.includes('teacher')) {
        updatePreferredRoleToTeacher()
      }
      else if (data.roles.includes('student')) history.push('/student-dashboard');
      else history.push('/teacher-dashboard');
    },
    onError: (error) => {
      console.error(error);
      message.error(<Typography.Text data-cy="components-loginpage-error-text">{error.message}</Typography.Text>);
    },
  });

  const onSubmitForm = useCallback(
    (values: ILoginProps) => {
      submitLogin({
        variables: {
          data: values,
        },
      });
    },
    [submitLogin],
  );

  const onLoginWithSSO = useCallback(({ provider }: { provider: 'clever' | 'classLink' | 'google' | 'canvas' | 'googleTest' }) => {
    const redirect_uri = window.location.origin;
    const classLinkClientId =
      process.env.REACT_APP_CLASSLINK_CLIENT_ID || 'c16318123360510b4e4a607247e29ecc6256f09d2b90';
    const googleClientId =
      process.env.REACT_APP_GOOGLE_CLIENT_ID ||
      '1004692858571-ur6ao926rvd9pkgcm6v2c1q9uff8mhud.apps.googleusercontent.com';

    const scope = `https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.courses https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/classroom.rosters https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.profile.photos https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.coursework.me.readonly https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/userinfo.email`;

    let url = '';
    if (provider === 'classLink') {
      url = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${redirect_uri}/sso?provider=classlink&client_id=${classLinkClientId}&response_type=code`;
    } else if (provider === 'clever') {
      url = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirect_uri}/sso?provider=clever&client_id=6fd9484b33eac85d533f`;
    } else if (provider === 'google') {
      url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${googleClientId}&response_type=code&scope=email&redirect_uri=${redirect_uri}/sso?provider=google&service=lso&o2v=2&flowName=GeneralOAuthFlow`;
    } else if (provider === 'canvas') {
      url = `${localStorage.getItem('CANVAS_API_DOMAIN')}/login/oauth2/auth?client_id=${localStorage.getItem('CANVAS_CLIENT_ID')}&response_type=code&redirect_uri=${redirect_uri}/sso?provider=canvas`;
    } else if (provider === 'googleTest') {
      url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${googleClientId}&response_type=code&scope=${scope}&redirect_uri=${redirect_uri}/sso?provider=googleTest&service=lso&o2v=2&flowName=GeneralOAuthFlow&access_type=offline&prompt=consent`;
    }
    const width = 600;
    const height = 800;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const windowFeatures = `scrollbars=yes,resizable=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,top=${top},left=${left},width=${width},height=${height}`;
    window.open(url, 'authPopup', windowFeatures);
  }, []);

  const handleOrganizationSelect = (value: string|undefined) => {  
    const selectedOrg = organizationList.find((org: Organization) => org.id === value)
    localStorage.setItem('CANVAS_API_DOMAIN', selectedOrg.apiDomain)
    localStorage.setItem('CANVAS_ORG_ID', selectedOrg.organization.id)
    localStorage.setItem('CANVAS_CLIENT_ID', selectedOrg.clientId);
  
   };

  const handleProceed = () => {
    if (!localStorage.getItem('CANVAS_ORG_ID')) {
      alert("Please select an organization before proceeding.");
      return;
    }
    setIsModalVisible(false);
    onLoginWithSSO({ provider: "canvas" });


  };
  const loadingSSO = loadingCleverSSO || loadingClassLinkSSO || loadingGoogleSSO || loadingGoogleSSOTest || loadingCanvasSSO;

  return (
    <S.Container data-cy="components-loginpage-container">
      <S.RowSP justify="center" align="middle">
        <S.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Login To Your Account</S.Title>
          <S.RowForm gutter={16} align="middle">
            <Col span={10} xs={24} md={24} xl={10} lg={10}>
              <Form onFinish={onSubmitForm} layout="vertical">
                <AntdForm.Item
                  label="Email"
                  name="username"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <Input placeholder="Type your email here..." disabled={loadingSSO} />
                </AntdForm.Item>
                <AntdForm.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input
                    placeholder="Type your password here..."
                    type="password"
                    disabled={loadingSSO}
                  />
                </AntdForm.Item>
                <Row justify="end">
                  <Link data-cy="components-loginpage-forgotpassword-link" to="/forgot-password">
                    FORGOT PASSWORD?
                  </Link>
                </Row>
                <AntdForm.Item>
                  <Button
                    data-cy="components-loginpage-login-button"
                    text="Login"
                    htmlType="submit"
                    loading={loading || loadingSSO}
                    block
                    style={{
                      width: '50%',
                      margin: '0.5em auto 0', // Adjusted margin for less space between login button and "Or" container
                      textAlign: 'center',
                      display: 'block',
                    }}
                  />
                </AntdForm.Item>
              </Form>
            </Col>
            <S.OrContainer>
              <S.ColR>
                <S.OrText>Or</S.OrText>
              </S.ColR>
            </S.OrContainer>
            <S.ColSSO span={10} xs={24} lg={12} md={12} xl={12}>
              <S.RowSSO gutter={[16, 10]}>
                <Col span={24} xs={24} lg={24} md={24} xl={24}>
                  <S.GoogleSSOButton
                    data-cy="components-loginpage-button-loginwithgoogle"
                    onClick={() => onLoginWithSSO({ provider: 'google' })}
                  >
                    <img src={googleLogoSrc} alt="Google Logo" /> <S.SSOButtonDivider /> Continue with Google
                  </S.GoogleSSOButton>
                </Col>
                <Col span={24} xs={24} lg={24} md={24} xl={24}>
                  <S.CleverSSOButton
                    data-cy="components-loginpage-button-loginwithclever"
                    onClick={() => onLoginWithSSO({ provider: 'clever' })}
                  >
                    <img src={cleverC} alt="Clever Logo" /> <S.SSOButtonDivider /> Continue with Clever
                  </S.CleverSSOButton>
                </Col>
                <Col span={24} xs={24} lg={24} md={24} xl={24}>
                  <S.ClassLinkSSOButton
                    data-cy="components-loginpage-button-loginwithclasslink"
                    onClick={() => onLoginWithSSO({ provider: 'classLink' })}
                  >
                    <img src={classLinkLogoSrc} alt="ClassLink Logo" /> <S.SSOButtonDivider /> Continue with ClassLink
                  </S.ClassLinkSSOButton>
                </Col>
                <Col span={24} xs={36} lg={24} md={24} xl={24}>
                  <S.GoogleClassroomSSOButton
                    data-cy="components-loginpage-button-loginwithgoogleclassroom"
                    onClick={() => onLoginWithSSO({ provider: 'googleTest' })}
                  >
                    <img src={googleClassroom} alt="Google Classroom Logo" /> <S.SSOButtonDivider /> Login with Google Classroom
                  </S.GoogleClassroomSSOButton>
                </Col>
                <Col span={24} xs={36} lg={24} md={24} xl={24}>
                  <S.CanvasSSOButton
                    data-cy="components-loginpage-button-loginwithgoogle"
                    onClick={handleLoginWithCanvas}
                  >
                    <img src={canvas} alt="Canvas Logo" /><S.SSOButtonDivider /> Login with Canvas
                  </S.CanvasSSOButton>
                  <div>
                    <Button onClick={() => setIsModalVisible(true)}>Select Organization</Button>
                    <Modal
                      title="Select Canvas Organization"
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                      style={{ display: 'block' }}
                    >
                      <div id="modal-content">
                        {loadingOrganizations ? (
                          <p>Loading organizations...</p>
                        ) : orgError ? (
                          <p>Error loading organizations: {orgError.message}</p>
                        ) : (
                          <>
                            {organizationList.length > 0 ? (
                              <Select
                                placeholder="Select an organization"
                                style={{ width: '100%', marginBottom: '1rem' }}
                                onChange={(value:string) => handleOrganizationSelect(value)}
                              >
                                {organizationList.map((organization: Organization) => (
                                  <Option
                                    key={organization.id}
                                    value={organization.id}>
                                    {organization.organization.name}
                                  </Option>
                                ))}
                              </Select>
                            ) : (
                              <p>No organizations found.</p>
                            )}
                            <S.StyledButton
                              type="primary"
                              onClick={handleProceed}
                              block
                            >
                              Proceed
                            </S.StyledButton>
                          </>
                        )}
                      </div>
                    </Modal>
                  </div>
                </Col>
              </S.RowSSO>
            </S.ColSSO>
          </S.RowForm>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );

};

export default withRouter(LoginPage);