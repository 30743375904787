import React, { useCallback, useMemo, useState } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import * as S from './styles';
import { Col, Divider, Dropdown, Menu, message, Row, Skeleton } from 'antd';
import {
  GQL_EditInvestigationDurationInput,
  GQL_InvestigationDashboard,
  GQL_InvestigationTeacherEntry,
} from '../../types/investigation';
import RangePicker from '../../shared/RangePicker';
import { formatDateTime, toDateFormat } from '../../utils/date';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { FiMoreVertical } from 'react-icons/fi';
import { GQL_ClassResponse } from '../../types/class';
import ModalConfirm from '../../shared/ModalConfirm';
import Button from '../../shared/Button';
import { useAuth } from '../../hooks/useAuth';

type Props = RouteComponentProps<{ investigationId: string }>;

const InvestigationSettings: React.FC<Props> = (props) => {
  const { investigationId } = props.match.params;
  const [investigationFinished, setInvestigationFinished] = useState<boolean>();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [editingDuration, setEditingDuration] = useState(false);
  const [classInfo, setClassInfo] = useState<GQL_ClassResponse>();
  const [duration, setDuration] = useState<{
    startDate: string;
    endDate: string;
  }>();
  const history = useHistory();
  const { isFacilitator } = useAuth();
  const classTitle = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);
  const [getClass] = useLazyQuery<{ getClass: GQL_ClassResponse }, { data: { classId: string } }>(
    gqlSchema.ClassSchema.query.CLASS.CLASSES.getClass,
    {
      onCompleted: (data) => {
        setClassInfo(data.getClass);
      },
      onError: (err) => {
        message.error('There was an error loading class: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const { data: investigation, loading } = useQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationTeacherEntry },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationSettings, {
    variables: { id: investigationId },
    onCompleted: (data) => {
      setInvestigationFinished(new Date(data?.getInvestigationByIdForTeacher.dueDate || '').getTime() > Date.now());
      getClass({ variables: { data: { classId: data?.getInvestigationByIdForTeacher.classId } } });
    },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const typeName =
    investigation?.getInvestigationByIdForTeacher?.discipline?.subject === 'Engineering'
      ? 'Design Challenge'
      : 'Investigation';

  const [editInvestigationDuration] = useMutation<
    { editInvestigationDuration: GQL_InvestigationDashboard },
    { data: GQL_EditInvestigationDurationInput }
  >(gqlSchema.InvestigationSchema.mutations.EDIT.editInvestigationDuration, {
    onCompleted: (data) => {
      message.success(`${typeName} duration updated successfully`);
    },
    onError: (err) => {
      message.error(`There was an error trying to edit the ${typeName?.toLocaleLowerCase()}, please try again later`);
    },
    update(cache, { data }) {
      const investigationTeacherSummary = {
        __typename: 'InvestigationTeacherSummary',
        id: data?.editInvestigationDuration.investigationId,
        classId: data?.editInvestigationDuration.classId,
      };

      cache.modify({
        id: cache.identify(investigationTeacherSummary),
        fields: {
          dueDate() {
            return data?.editInvestigationDuration.dueDate;
          },
          startDate() {
            return data?.editInvestigationDuration.startDate;
          },
        },
      });

      const investigationTeacherEntry = {
        __typename: 'InvestigationTeacherEntry',
        investigationId: data?.editInvestigationDuration.investigationId,
      };

      cache.modify({
        id: cache.identify(investigationTeacherEntry),
        fields: {
          dueDate() {
            return data?.editInvestigationDuration.dueDate;
          },
          startDate() {
            return data?.editInvestigationDuration.startDate;
          },
        },
      });
    },
  });

  const [removeInvestigation, { loading: loadingRemoveInvestigation }] = useMutation<
    { removeInvestigationFromClasses: boolean },
    { id: string }
  >(gqlSchema.InvestigationSchema.mutations.DELETE.removeInvestigationFromClasses, {
    onCompleted: (data) => {
      if (data.removeInvestigationFromClasses) {
        history.push('/teacher-dashboard');
        message.success(`${typeName} deleted successfully`);
      } else {
        message.error('There was an error trying to delete the investigation, please try again later');
      }
    },
    onError: (err) => {
      message.error('There was an error trying to delete the investigation, please try again later');
    },
    update(cache, { data }) {
      if (data?.removeInvestigationFromClasses) {
        cache.modify({
          fields: {
            getInvestigationsByTeacher(existingInvestigations = [], { readField }) {
              return existingInvestigations.filter(
                (investigationItem: any) =>
                  readField('investigationId', investigationItem) !==
                  investigation?.getInvestigationByIdForTeacher.investigationId,
              );
            },
          },
          broadcast: true,
        });
        const investigationObj = {
          __typename: 'ClassInvestigation',
          investigationId: investigation?.getInvestigationByIdForTeacher.investigationId,
        };
        cache.modify({
          id: cache.identify(investigationObj),
          fields: {
            getInvestigationsByClassId(existingInvestigations = [], { readField }) {
              return existingInvestigations.filter(
                (investigationItem: any) => readField('investigationId', investigationItem) !== investigationId,
              );
            },
          },
          broadcast: true,
        });
      }
    },
  });

  const handleDurationEditingButton = useCallback(() => {
    if (classInfo && editingDuration && duration) {
      editInvestigationDuration({
        variables: {
          data: {
            classId: classInfo.id,
            investigationId,
            startDate: new Date(toDateFormat(duration.startDate)).getTime(),
            endDate: new Date(toDateFormat(duration.endDate)).getTime(),
          },
        },
      });
    }
    setEditingDuration(!editingDuration);
  }, [classInfo, duration, editInvestigationDuration, editingDuration, investigationId]);

  const onRemoveInvestigation = useCallback(() => {
    removeInvestigation({ variables: { id: investigationId } });
  }, [investigationId, removeInvestigation]);

  const menuOverlay = useCallback(
    () => (
      <Menu>
        <Menu.Item onClick={() => setDeleteConfirmVisible(true)}>Delete Investigation</Menu.Item>
      </Menu>
    ),
    [],
  );

  return (
    <PageWithTitle
      title={typeName + ' Settings'}
      backPageUrl={`/teacher-dashboard/investigation-summary/${investigationId}`}
      extra={
        <Row justify="end">
          <Button
            data-cy="components-investigationsettings-summary-button"
            text={`Edit ${typeName}`}
            onClick={() =>
              history.push(
                `/teacher-investigation/${investigation?.getInvestigationByIdForTeacher?.discipline?.subject}/${investigationId}/settings`,
                {
                  backUrl: history.location.pathname,
                },
              )
            }
          />
        </Row>
      }
    >
      <S.InvestigationSettingsContainer>
        <Row>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <Row align="middle">
              <Col span={18}>
                <S.Title>{typeName} Name</S.Title>
                {loading ? (
                  <Skeleton.Input active size="small" />
                ) : (
                  <S.SInput
                    disabled={true}
                    bordered={false}
                    defaultValue={investigation?.getInvestigationByIdForTeacher.title}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <Row align="middle">
              <Col span={16}>
                <S.Title>{typeName} Duration</S.Title>
                {editingDuration ? (
                  <RangePicker
                    value={duration}
                    onChange={setDuration}
                    allowClear={false}
                    defaultValue={{
                      startDate: formatDateTime(investigation?.getInvestigationByIdForTeacher.startDate),
                      endDate: formatDateTime(investigation?.getInvestigationByIdForTeacher.dueDate),
                    }}
                  />
                ) : (
                  <S.SubText>
                    {duration?.startDate || formatDateTime(investigation?.getInvestigationByIdForTeacher.startDate)} -{' '}
                    {duration?.endDate || formatDateTime(investigation?.getInvestigationByIdForTeacher.dueDate)}
                  </S.SubText>
                )}
              </Col>
              <Col span={6} offset={2}>
                <S.EditButton
                  data-cy="components-investigationsettings-duration-editing-button"
                  type="primary"
                  shape="circle"
                  minHeight={24}
                  icon={editingDuration ? <BiCheck /> : <BiEdit />}
                  onClick={handleDurationEditingButton}
                />
              </Col>
            </Row>
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <Col>
              <S.Title>{typeName} Students</S.Title>
            </Col>
            <Col>
              {loading ? (
                <Skeleton.Input active size="small" />
              ) : (
                <S.SubText>{classInfo?.numberOfStudents} Enrolled Students</S.SubText>
              )}
            </Col>
          </Col>
          <Divider />
        </Row>
        <Row>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <Col>
              <S.Title>Investigation {classTitle}</S.Title>
            </Col>
            <Col>
              {loading ? (
                <Skeleton.Input active size="small" />
              ) : (
                <S.TagButton text={classInfo?.name} background={classInfo?.colorHex} shape="round" minHeight={24} />
              )}
            </Col>
          </Col>
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <Col>
              <S.Title>{typeName} Owner</S.Title>
            </Col>
            <Col>
              <S.SubText>
                {loading ? (
                  <Skeleton.Input active size="small" />
                ) : (
                  <p>{`${classInfo?.teacherName} - ${classInfo?.teacherEmail}`}</p>
                )}
              </S.SubText>
            </Col>
          </Col>
          <Col xxl={5} xl={5} lg={12} md={20} sm={20} xs={20}>
            <Col>
              <S.Title>{typeName} Status</S.Title>
            </Col>
            <Col>
              <S.StatusText finished={investigationFinished}>
                {investigationFinished !== undefined && (investigationFinished ? 'Ongoing' : 'Finished')}
              </S.StatusText>
            </Col>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={4} sm={4} xs={4}>
            <Dropdown overlay={menuOverlay()} placement="bottomRight" trigger={['click']}>
              <S.MoreButton icon={<FiMoreVertical size={24} />} minHeight={24} shape="circle" />
            </Dropdown>
          </Col>
        </Row>
      </S.InvestigationSettingsContainer>
      <ModalConfirm
        visible={deleteConfirmVisible}
        setVisible={setDeleteConfirmVisible}
        title={`You are deleting ${typeName?.toLocaleLowerCase()} ${
          investigation?.getInvestigationByIdForTeacher.title
        }`}
        lineInfo1={`Are you sure you want to delete ${typeName?.toLocaleLowerCase()} ${
          investigation?.getInvestigationByIdForTeacher.title
        } for ${classInfo?.name}?`}
        lineInfo2={`All the progress and grades will be permanently deleted`}
        deleteButtonTitle={`Delete ${typeName}`}
        nameToCheck={investigation?.getInvestigationByIdForTeacher.title || ''}
        loading={loadingRemoveInvestigation}
        deleteFn={onRemoveInvestigation}
        errorMessage={`The ${typeName} name is not the same`}
      />
    </PageWithTitle>
  );
};

export default withRouter(InvestigationSettings);
