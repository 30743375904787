/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import Spacer from '../../../../shared/Spacer';
import Select from '../../../../shared/Select';
import SelectOption from '../../../../shared/Select/Option';
import { GQL_InvestigationStandard } from '../../../../types/investigationStandard';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { INVESTIGATION_STANDARDS } from '../../../../types/standards';
import { message } from 'antd';
import { GQL_InvestigationCatalogEntry } from '../../../../types/investigation';
import { SelectValue } from 'antd/lib/select';
import { sortTexasStandardsByCode } from '../../../../utils/utils';

type NextGenerationStandardsSelectPartSettingProps = {
  currentStandards: GQL_InvestigationStandard[];
  assessment: GQL_InvestigationCatalogEntry;
  onStandardsChange: (values: GQL_InvestigationStandard[]) => void;
};

export const NextGenerationStandardsSelectPartSetting = (props: NextGenerationStandardsSelectPartSettingProps) => {
  const { assessment, onStandardsChange, currentStandards } = props;

  const [ngssStandardListField, setNgssStandardList] = useState<GQL_InvestigationStandard[] | undefined>(
    currentStandards || [],
  );

  const { data: ngssStandardsData, loading: loadingNGSSStateStandards } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.NGSS,
    },
    onError: (error) => {
      message.error(
        `There was an error in fetching the next generation standards - ${error.message || 'Unexpected Error'}`,
      );
    },
  });

  const compareSelectedGradeBandToNGStandardGradeAndDiscipline = useCallback(
    (
      standard: GQL_InvestigationStandard,
      selectedGradeBand: string | undefined,
      disciplineId: string | undefined,
    ): boolean => {
      const currentDiscipline = assessment?.discipline?.name;
      console.log("selectedDiscipline: ", currentDiscipline);
      console.log("selectedGradeBand: ", selectedGradeBand);
      console.log("current Standard Grade: ", standard.grade);
      if (!currentDiscipline || !selectedGradeBand || !disciplineId) return false;
     
      if (!standard.discipline?.toLocaleLowerCase().includes((currentDiscipline || '')?.toLocaleLowerCase()))
        return false;

      const pattern = /\b(?:Grade|School)\b/gi;
      const sanitizeBand = standard.grade.replace(pattern, '');
      console.log("Grade band for DB: ", sanitizeBand);
      
      let parsedStandardGrade = parseInt(sanitizeBand);
      if(isNaN(parsedStandardGrade)) parsedStandardGrade = 0;
      console.log("Parsed Grade Band from DB: ", parsedStandardGrade);

      if (selectedGradeBand.includes('-')) {
        const [lowGrade, highGrade] = selectedGradeBand.split('-').map((value) =>{
          const val = parseInt(value);
          if(isNaN(val)) {
            return 0;
          } else {
            return val;
          }
        });
        return lowGrade <= parsedStandardGrade && parsedStandardGrade <= highGrade;
      }

      let parsedSelectedGradeBand = parseInt(selectedGradeBand);
      if(isNaN(parsedSelectedGradeBand)) parsedSelectedGradeBand = 0;

      return parsedStandardGrade === parsedSelectedGradeBand;
    },
    [assessment],
  );

  const ngssStandardList = useMemo(
    () =>
      ngssStandardsData?.getStateStandards
        .filter((standard) =>
          compareSelectedGradeBandToNGStandardGradeAndDiscipline(
            standard,
            assessment?.discipline?.gradeBand,
            assessment?.discipline?.id,
          ),
        )
        .sort(sortTexasStandardsByCode),
    [ngssStandardsData, compareSelectedGradeBandToNGStandardGradeAndDiscipline, assessment],
  );

  const handleStandardsChange = useCallback(
    (v: SelectValue) => {
      const source = ngssStandardsData?.getStateStandards;

      const standards = (v as string[]).map((code) => {
        const standard = (source || []).find((standard) => standard.code === code);
        return {
          id: standard?.id,
          code: standard?.code,
          grade: standard?.grade,
        };
      });

      setNgssStandardList(standards as GQL_InvestigationStandard[]);

      if (onStandardsChange) {
        onStandardsChange(standards as GQL_InvestigationStandard[]);
      }
    },
    [ngssStandardsData, onStandardsChange],
  );

  return (
    <>
      <Spacer />
      <h2>NGSS Standards</h2>
      <Select
        placeholder="NGSS Standards"
        optionLabelProp="label"
        showSearch={false}
        mode="multiple"
        dropdownMatchSelectWidth={false}
        showArrow
        loading={loadingNGSSStateStandards}
        value={ngssStandardListField?.map((standard) => standard.code)?.sort()}
        onChange={(change) => handleStandardsChange(change)}
      >
        {ngssStandardList?.map((standard) => (
          <SelectOption
            value={standard.code}
            label={standard.code}
            key={standard.id}
            style={{
              maxWidth: '400px',
            }}
          >
            {standard.code} - {standard.fullStatement}
          </SelectOption>
        ))}
      </Select>
    </>
  );
};
