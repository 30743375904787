/* eslint-disable complexity */
import { Col, Form as AntdForm, message, Popconfirm, Row } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { GQL_UserDetailsResponse } from '../../../types/profile';
import Form from '../../../shared/Form';
import { roles } from '../../../utils/roles';
import * as S from './styles';
import { formatDateTime } from '../../../utils/date';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { useLazyQuery, useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from '../../../hooks/useAuth';
import Select from '../../../shared/Select';
import { GQL_OrganizationResponse } from '../../../types/organization';
import SelectOption from '../../../shared/Select/Option';
import Switch from '../../../shared/Switch';
import TagInput from '../../../shared/TagInput';
import classlinkLogo from '../../../assets/idpSource/classlinkLogo.png';
import adiLogo from '../../../assets/idpSource/adiLogo.png';
import cleverLogo from '../../../assets/idpSource/cleverLogo.png';
import { MdCancel } from 'react-icons/md';
import RoleEditorInput from '../../../shared/RoleEditorInput';

interface Props {
  user?: GQL_UserDetailsResponse;
}

const UserInfo: React.FC<Props> = (props) => {
  const { user } = props;
  const [changeNameVisible, setChangeNameVisible] = useState(false);
  const [changeTAVisible, setChangeTAVisible] = useState(false);
  const [changeLastNameVisible, setChangeLastNameVisible] = useState(false);
  const [changeOrganizationVisible, setChangeOrganizationVisible] = useState(false);
  const [userIsWriter, setUserIsWriter] = useState(false);

  const [form] = useForm();
  const { isAdiAdmin, isAdiSuperAdmin, isOrganizationAdiAdmin } = useAuth();
  const canEdit = useMemo(
    () => !isAdiAdmin || (isAdiAdmin && !user?.roles.some((role: string) => role === 'adi_super_admin')),
    [isAdiAdmin, user],
  );

  const [changeTeacherOrgazanition, { loading: loadingChangeTeacherOrg }] = useMutation(
    gqlSchema.OrganizationSchema.mutations.EDIT.addTeacherToOrganization,
    {
      refetchQueries: ['GetUserDetails'],
      awaitRefetchQueries: true,
      onCompleted: () => message.success('User transferred to organization'),
      onError: (err) => message.error(err.message),
      update(cache, { data }) {
        if (data?.addTeacherToOrganization) {
          const userResponse = {
            __typename: 'UserDetailsResponse',
            id: user?.id,
          };
          const values = form.getFieldsValue();

          cache.modify({
            id: cache.identify(userResponse),
            fields: {
              organization() {
                return values?.organization;
              },
            },
          });
        }
      },
    },
  );
  const [removeTeacherFromOrganization, { loading: loadingRemoveTeacherOrg }] = useMutation(
    gqlSchema.OrganizationSchema.mutations.EDIT.removeTeacherFromOrganization,
    {
      onCompleted: () => {
        message.success('User removed from organization');
      },
      onError: (err) => message.error(err.message),
    },
  );
  const [submitEditUser] = useMutation(gqlSchema.UserSchema.mutations.EDIT.editUser, {
    onCompleted: ({ editUser }: { editUser: boolean }) => {
      if (editUser) message.success(`${user?.name} account updated successfully`);
      else message.error(`Error updating account for ${user?.name}`);
    },
    onError: (error) => {
      message.error(error.message);
    },
    update(cache, { data }) {
      if (data?.editUser) {
        const userResponse = {
          __typename: 'UserDetailsResponse',
          id: user?.id,
        };
        const values = form.getFieldsValue();

        cache.modify({
          id: cache.identify(userResponse),
          broadcast: true,
          fields: {
            firstName() {
              return values.firstName;
            },
            lastName() {
              return values.lastName;
            },
          },
        });
      }
    },
  });

  const [loadOrganizationData, { data: organizationsData, loading: loadingOrganizations }] = useLazyQuery<{
    getAllOrganizations: GQL_OrganizationResponse[];
  }>(gqlSchema.OrganizationSchema.queries.LIST.getAllOrganizations, {
    onError: (err) => {
      message.error('There was an error loading the organizations: ' + err.message || 'Unexpected Error');
    },
  });

  // load organization data only if user is admin
  useEffect(() => {
    if ((isAdiAdmin || isAdiSuperAdmin) && !organizationsData?.getAllOrganizations) {
      loadOrganizationData();
    }
  }, [isAdiAdmin, isAdiSuperAdmin, loadOrganizationData, organizationsData]);

  const [submitActivateUser] = useMutation(gqlSchema.UserSchema.mutations.EDIT.activateUser, {
    onCompleted: ({ activateUser }: { activateUser: boolean }) => {
      if (activateUser) message.success(`${user?.name} account activated successfully`);
      else message.error(`Error activating account for ${user?.name}`);
    },
    onError: (error) => {
      message.error(error.message);
    },
    update(cache, { data }) {
      if (data?.activateUser) {
        const userResponse = {
          __typename: 'UserDetailsResponse',
          id: user?.id,
        };
        cache.modify({
          id: cache.identify(userResponse),
          fields: {
            active() {
              return true;
            },
          },
        });
      }
    },
  });

  const [submitDeactivateUser] = useMutation(gqlSchema.UserSchema.mutations.EDIT.deactivateUser, {
    onCompleted: ({ deactivateUser }: { deactivateUser: boolean }) => {
      if (deactivateUser) message.success(`${user?.name} account deactivated successfully`);
      else message.error(`Error deactivating account for ${user?.name}`);
    },
    onError: (error) => {
      message.error(error.message);
    },
    update(cache, { data }) {
      if (data?.deactivateUser) {
        const userResponse = {
          __typename: 'UserDetailsResponse',
          id: user?.id,
        };
        cache.modify({
          id: cache.identify(userResponse),
          fields: {
            active() {
              return false;
            },
          },
        });
      }
    },
  });

  const [submitToogleWriter] = useMutation(gqlSchema.UserSchema.mutations.EDIT.toggleWriter, {
    onCompleted: ({ toggleWriter }: { toggleWriter: boolean }) => {
      if (toggleWriter) message.success(`Role Writer ${userIsWriter ? 'added' : 'removed'} successfully`);
      else message.error(`Error changing Role Writer configuration for ${user?.name}`);
    },
    onError: (error) => {
      message.error(error.message);
    },
    update(cache, { data }) {
      if (data?.toggleWriter) {
        const userResponse = {
          __typename: 'UserDetailsResponse',
          id: user?.id,
        };
        cache.modify({
          id: cache.identify(userResponse),
          fields: {
            roles(existingRoles = []) {
              if (!userIsWriter) return [...existingRoles, 'writer'];
              return existingRoles.filter((r: any) => {
                return r !== 'writer';
              });
            },
          },
        });
      }
    },
  });

  const handleOkWriter = useCallback(() => {
    submitToogleWriter({
      variables: {
        userId: user?.id,
        isWriter: !userIsWriter,
      },
    });
    setUserIsWriter(!userIsWriter);
  }, [submitToogleWriter, user, userIsWriter]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        assistantsAllowed: user.assistantsAllowed,
      });
      setUserIsWriter(user.roles.some((r) => r === 'writer'));
    }
  }, [form, user]);

  const renderRoles = useCallback(() => {
    if (user) {
      const existingRoles = roles.filter((r) => user.roles.includes(r.value));
      return <RoleEditorInput userId={user?.id} existingRoles={existingRoles} marginTop={5} />;
    }
    return null;
  }, [user]);

  const renderIDP = useCallback(() => {
    switch (user?.source) {
      case 'ADI': {
        return (
          <div>
            <img src={adiLogo} alt="ADI" style={{ height: '100px', width: '150px' }} />
          </div>
        );
      }
      case 'Clever': {
        return (
          <div>
            <img src={cleverLogo} alt="Clever" style={{ height: '100px', width: '100px' }} />
          </div>
        );
      }

      case 'Classlink': {
        return (
          <div>
            <img src={classlinkLogo} alt="Classlink" style={{ height: '100px', width: '100px' }} />
          </div>
        );
      }
    }
    return null;
  }, [user]);

  const handleOk = useCallback(() => {
    if (user?.active) submitDeactivateUser({ variables: { userId: user?.id } });
    else submitActivateUser({ variables: { userId: user?.id } });
  }, [submitActivateUser, submitDeactivateUser, user]);

  const userIsTeacher = useMemo(() => user?.roles.some((r) => r === 'teacher'), [user]);
  const userIsOrgAdmin = useMemo(() => user?.roles.some((r) => r === 'organization_admin'), [user]);
  const userIsJustOrgAdmin = useMemo(() => userIsOrgAdmin && !userIsTeacher, [userIsOrgAdmin, userIsTeacher]);

  const handleEditingButton = useCallback(
    (visibility: boolean, setVisibility: Dispatch<SetStateAction<boolean>>) => {
      if (visibility) {
        const values = form.getFieldsValue();
        submitEditUser({
          variables: {
            data: {
              id: user?.id,
              firstName: values.firstName,
              lastName: values.lastName,
              assistantsAllowed: isNaN(+values.assistantsAllowed) ? undefined : +values.assistantsAllowed,
            },
          },
        });
      }
      setVisibility(!visibility);
    },
    [form, submitEditUser, user],
  );

  const handleCancelEditingOrganization = useCallback((setVisibility: Dispatch<SetStateAction<boolean>>) => {
    setVisibility(false);
  }, []);

  const handleEditOrganization = useCallback(() => {
    const values = form.getFieldsValue();
    if (changeOrganizationVisible && values.organization && user?.id) {
      changeTeacherOrgazanition({
        variables: {
          teacherId: user?.id,
          organizationId: values.organization,
        },
      });
    }
    if (changeOrganizationVisible && values.organization == null && user?.id) {
      removeTeacherFromOrganization({
        variables: {
          teacherId: user?.id,
        },
      });
    }
    setChangeOrganizationVisible(!changeOrganizationVisible);
  }, [changeOrganizationVisible, form, changeTeacherOrgazanition, removeTeacherFromOrganization, user]);

  const [submitToggleTeacher] = useMutation(gqlSchema.UserSchema.mutations.EDIT.toggleTeacher, {
    onCompleted: ({ toggleTeacher }: { toggleTeacher: boolean }) => {
      if (toggleTeacher) message.success(`Teacher role ${!userIsTeacher ? 'added' : 'removed'} successfully`);
      else message.error(`Error changing teacher role configuration for ${user?.name}`);
    },
    onError: (error) => {
      message.error(error.message);
    },
    update(cache, { data }) {
      if (data?.toggleTeacher) {
        const userResponse = {
          __typename: 'UserDetailsResponse',
          id: user?.id,
        };
        cache.modify({
          id: cache.identify(userResponse),
          fields: {
            roles(existingRoles = []) {
              if (!userIsTeacher) return [...existingRoles, 'teacher'];
              return existingRoles.filter((r: any) => {
                return r !== 'teacher';
              });
            },
          },
        });
      }
    },
  });

  const handleOkTeacher = useCallback(() => {
    submitToggleTeacher({
      variables: {
        userId: user?.id,
        isTeacher: !userIsTeacher,
      },
    });
  }, [submitToggleTeacher, user, userIsTeacher]);

  const renderBecomeTeacher = useMemo(
    () =>
      (isAdiAdmin || isAdiSuperAdmin || isOrganizationAdiAdmin) &&
      userIsOrgAdmin && (
        <S.Line md={24} lg={8}>
          <S.Line span={24}>
            <Popconfirm
              placement="topLeft"
              title={`Are you sure you want to ${userIsTeacher ? 'disable' : 'enable'} teacher access?`}
              onConfirm={handleOkTeacher}
              okText="Yes"
              cancelText="No"
            >
              <Switch
                type="grid"
                title={<S.TitleInput>Assign as Teacher</S.TitleInput>}
                value={!userIsJustOrgAdmin}
                titleAlign="left"
              />
            </Popconfirm>
          </S.Line>
        </S.Line>
      ),
    [
      isAdiAdmin,
      isAdiSuperAdmin,
      isOrganizationAdiAdmin,
      userIsOrgAdmin,
      handleOkTeacher,
      userIsJustOrgAdmin,
      userIsTeacher,
    ],
  );
  return (
    <S.Body span={24}>
      <Form form={form}>
        <Row gutter={24} style={{ padding: 20 }}>
          <S.Line md={24} lg={8}>
            <S.TitleInput>First Name</S.TitleInput>
            <Row gutter={8} align="middle">
              <Col span={20}>
                <AntdForm.Item name="firstName">
                  <S.SInput id="firstName" disabled={!changeNameVisible} bordered={changeNameVisible} />
                </AntdForm.Item>
              </Col>

              {canEdit && (
                <Col span={changeNameVisible ? 2 : 4} style={{ textAlign: 'end' }}>
                  <S.EditButton
                    data-cy="components-userdetailspage-userinfo-firstname-edit-button"
                    type="primary"
                    shape="circle"
                    style={{ float: 'right' }}
                    disabled={loadingChangeTeacherOrg || loadingRemoveTeacherOrg}
                    icon={changeNameVisible ? <BiCheck /> : <BiEdit />}
                    onClick={() => handleEditingButton(changeNameVisible, setChangeNameVisible)}
                  />
                </Col>
              )}

              {canEdit && changeNameVisible && (
                <Col span={2} style={{ textAlign: 'end' }}>
                  <S.EditButton
                    data-cy="components-userdetailspage-userinfo-firstname-cancel-button"
                    type="primary"
                    shape="circle"
                    style={{ float: 'right' }}
                    icon={<MdCancel />}
                    onClick={() => handleCancelEditingOrganization(setChangeNameVisible)}
                  />
                </Col>
              )}
            </Row>
          </S.Line>

          <S.Line md={24} lg={8}>
            <S.TitleInput>Last Name</S.TitleInput>
            <Row gutter={8} align="middle">
              <Col span={20}>
                <AntdForm.Item name="lastName">
                  <S.SInput id="lastName" disabled={!changeLastNameVisible} bordered={changeLastNameVisible} />
                </AntdForm.Item>
              </Col>

              {canEdit && (
                <Col span={changeLastNameVisible ? 2 : 4} style={{ textAlign: 'end' }}>
                  <S.EditButton
                    data-cy="components-userdetailspage-userinfo-lastname-edit-button"
                    type="primary"
                    shape="circle"
                    style={{ float: 'right' }}
                    icon={changeLastNameVisible ? <BiCheck /> : <BiEdit />}
                    disabled={loadingChangeTeacherOrg || loadingRemoveTeacherOrg}
                    onClick={() => handleEditingButton(changeLastNameVisible, setChangeLastNameVisible)}
                  />
                </Col>
              )}

              {canEdit && changeLastNameVisible && (
                <Col span={2} style={{ textAlign: 'end' }}>
                  <S.EditButton
                    data-cy="components-userdetailspage-userinfo-lastname-cancel-button"
                    type="primary"
                    shape="circle"
                    style={{ float: 'right' }}
                    icon={<MdCancel />}
                    onClick={() => handleCancelEditingOrganization(setChangeLastNameVisible)}
                  />
                </Col>
              )}
            </Row>
          </S.Line>

          <S.Line md={24} lg={8}>
            <S.TitleInput>Email</S.TitleInput>
            <S.SInput id="email" value={user?.email} bordered={false} disabled />
          </S.Line>

          <S.CDivider />
        </Row>

        <Row gutter={24} style={{ padding: 20 }}>
          <S.Line md={24} lg={8}>
            <S.TitleInput>Roles</S.TitleInput>
            {renderRoles()}
          </S.Line>

          <S.Line md={24} lg={8}>
            <S.TitleInput>Organization</S.TitleInput>
            <Row gutter={8} align="middle">
              <Col span={20}>
                {changeOrganizationVisible ? (
                  <AntdForm.Item name="organization">
                    <Select
                      data-cy="components-userdetailspage-userinfo-organization-select"
                      placeholder="No Organization"
                      showSearch
                      disabled={loadingChangeTeacherOrg || loadingRemoveTeacherOrg}
                      defaultValue={
                        organizationsData?.getAllOrganizations?.find((o) => o.name === user?.organization)?.id
                      }
                      filterOption={(input, option) =>
                        option?.children?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) ?? false
                      }
                      loading={loadingOrganizations}
                      allowClear={isAdiAdmin || isAdiSuperAdmin}
                    >
                      {organizationsData?.getAllOrganizations?.map((organization) => (
                        <SelectOption value={organization.id} key={organization.id}>
                          {organization.name}
                        </SelectOption>
                      ))}
                    </Select>
                  </AntdForm.Item>
                ) : (
                  <S.SInput
                    data-cy="components-userdetailspage-userinfo-organization-sinput"
                    value={
                      loadingChangeTeacherOrg || loadingRemoveTeacherOrg
                        ? 'Loading...'
                        : user?.organization || 'No Organization'
                    }
                    bordered={false}
                    disabled
                  />
                )}
              </Col>

              {(isAdiAdmin || isAdiSuperAdmin) && (
                <Col span={changeOrganizationVisible ? 2 : 4} style={{ textAlign: 'end' }}>
                  <S.EditButton
                    data-cy="components-userdetailspage-userinfo-organization-edit-button"
                    type="primary"
                    shape="circle"
                    style={{ float: 'right' }}
                    loading={loadingChangeTeacherOrg || loadingRemoveTeacherOrg}
                    disabled={loadingChangeTeacherOrg || loadingRemoveTeacherOrg}
                    icon={changeOrganizationVisible ? <BiCheck /> : <BiEdit />}
                    onClick={handleEditOrganization}
                  />
                </Col>
              )}

              {(isAdiAdmin || isAdiSuperAdmin) && canEdit && changeOrganizationVisible && (
                <Col span={2} style={{ textAlign: 'end' }}>
                  <S.EditButton
                    data-cy="components-userdetailspage-userinfo-organization-cancel-button"
                    type="primary"
                    shape="circle"
                    style={{ float: 'right' }}
                    icon={<MdCancel />}
                    onClick={() => handleCancelEditingOrganization(setChangeOrganizationVisible)}
                  />
                </Col>
              )}
            </Row>
          </S.Line>

          <S.Line md={24} lg={8}>
            <S.TitleInput>Enrollment Date</S.TitleInput>
            <S.SInput disabled bordered={false} value={formatDateTime(user?.enrollmentDate, 'MM.dd.yyyy HH:mm')} />
          </S.Line>

          <S.CDivider />
        </Row>

        <Row gutter={24} style={{ padding: 20 }}>
          <S.Line md={24} lg={8}>
            <S.TitleInput>Account Status</S.TitleInput>
            <Row gutter={8}>
              <Col span={12}>
                <S.Status $active={user?.active || false}> {user?.active ? 'Active' : 'Inactive'}</S.Status>
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <Popconfirm
                  placement="topRight"
                  title={
                    <div style={{ textAlign: 'center' }}>
                      <S.Info>
                        This will {user?.active ? 'deactivate' : 'activate'} {user?.name}'s account. Do you{' '}
                      </S.Info>
                      <S.Info>confirm that you want to perform this action?</S.Info>
                    </div>
                  }
                  onConfirm={handleOk}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  {canEdit && (
                    <S.DeactivateButton $active={user?.active || false}>
                      {user?.active ? 'Deactivate' : 'Activate'}
                    </S.DeactivateButton>
                  )}
                </Popconfirm>
              </Col>
            </Row>
          </S.Line>

          <S.Line md={24} lg={8}>
            <S.TitleInput>Tags</S.TitleInput>
            <TagInput
              userId={user?.id}
              existingUserTags={user?.tags}
              marginTop={5}
              queriesToRefetch={[
                {
                  query: gqlSchema.ClassSchema.query.CLASS.CLASSES.getUserDetails,
                  variables: { data: { id: user?.id } },
                },
              ]}
            />
          </S.Line>

          {renderBecomeTeacher}

          {userIsTeacher && (
            <>
              <S.Line md={24} lg={8}>
                <S.TitleInput>Teacher Assistants</S.TitleInput>
                <Row gutter={8} align="middle">
                  <Col span={20}>
                    <AntdForm.Item name="assistantsAllowed">
                      <S.SInput type="number" disabled={!changeTAVisible} bordered={changeTAVisible} />
                    </AntdForm.Item>
                  </Col>

                  {(isAdiSuperAdmin || isAdiAdmin) && (
                    <Col span={changeTAVisible ? 2 : 4} style={{ textAlign: 'end' }}>
                      <S.EditButton
                        type="primary"
                        shape="circle"
                        style={{ float: 'right' }}
                        icon={changeTAVisible ? <BiCheck /> : <BiEdit />}
                        disabled={loadingChangeTeacherOrg || loadingRemoveTeacherOrg}
                        onClick={() => handleEditingButton(changeTAVisible, setChangeTAVisible)}
                      />
                    </Col>
                  )}

                  {(isAdiSuperAdmin || isAdiAdmin) && changeTAVisible && (
                    <Col span={2} style={{ textAlign: 'end' }}>
                      <S.EditButton
                        type="primary"
                        shape="circle"
                        style={{ float: 'right' }}
                        icon={<MdCancel />}
                        onClick={() => handleCancelEditingOrganization(setChangeTAVisible)}
                      />
                    </Col>
                  )}
                </Row>
              </S.Line>

              <S.Line md={24} lg={8}>
                {isOrganizationAdiAdmin &&
                  !user?.roles.some((r) => r === 'organization_admin') &&
                  user?.roles.some((r) => r === 'teacher') && (
                    <S.Line span={24}>
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to ${!userIsWriter ? 'add' : 'remove'}  writer permissions?`}
                        onConfirm={handleOkWriter}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Switch
                          type="grid"
                          title={<S.TitleInput $padding="0 5px">Is Library Writer</S.TitleInput>}
                          value={userIsWriter}
                          titleAlign="left"
                        />
                      </Popconfirm>
                    </S.Line>
                  )}
              </S.Line>
            </>
          )}
        </Row>

        <S.Line md={24} lg={8}>
          <S.TitleInput>SSO</S.TitleInput>
          <Row gutter={8}>
            <Col span={12} style={{ padding: '15px' }}>
              {renderIDP()}
            </Col>
          </Row>
        </S.Line>
      </Form>
    </S.Body>
  );
};

export default UserInfo;
