import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Route as ReactDOMRoute, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { menuActions } from '../redux/modules';
import { Role } from '../constants/enums/Role';

type RouteType =
  | 'teacherRoute'
  | 'studentRoute'
  | 'teacherAssistantRoute'
  | 'adiRoute'
  | 'organizationRoute'
  | 'authenticatedRoute'
  | 'onlyUnauthenticatedRoute'
  | 'advancedSearchRoute'
  | 'writerRoute'
  | 'allowDisabled'
  | 'googleTeacherRoute'
  | 'googleStudentRoute'
  | 'canvasTeacherRoute'
  | 'canvasStudentRoute';

interface IRoute extends RouteProps {
  component?: any;
  routeTypes: RouteType[];
  root?: string;
}

const Route: React.FC<IRoute> = ({ routeTypes = [], component: Component, root = '', ...rest }) => {
  const {
    isLogged,
    isStudent,
    isTeacherOrFacilitator,
    isTeacherAssistant,
    isAdiAdmin,
    isAdiSuperAdmin,
    isOrganizationAdiAdmin,
    isSubscriptionUser,
    isWriter,
    isFacilitator,
    isAdvancedSearch,
    user,
    isUserDisabled,
    isUserSuspended,
    isGoogleTeacher,
    isGoogleStudent,
    isCanvasTeacher,
    isCanvasStudent,
  } = useAuth();
  const dispatch = useDispatch();
  const onlyUnauthenticated = useMemo(
    () => routeTypes.some((routeType: string) => routeType === 'onlyUnauthenticatedRoute'),
    [routeTypes],
  );

  const userDisabled = useMemo(() => isUserDisabled || isUserSuspended, [isUserDisabled, isUserSuspended]);

  const checkPermission = useCallback(
    (routeType: string) => {
      const routeConditions: Record<string, boolean|string> = (user?.preferredRole)?
       {'adiRoute': user.preferredRole===Role.ADI_SUPER_ADMIN || user.preferredRole===Role.ADI_ADMIN,
        'studentRoute': user.preferredRole===Role.STUDENT,
        'teacherRoute': (isTeacherOrFacilitator ||user.preferredRole===Role.TEACHER|| user.preferredRole===Role.FACILITATOR || isSubscriptionUser) && !userDisabled,
        'teacherAssistantRoute': user.preferredRole===Role.TEACHER_ASSISTANT && !userDisabled,
        'organizationRoute': user.preferredRole===Role.ORGANIZATION_ADMIN,
        'advancedSearchRoute': user.preferredRole===Role.ADVANCED_SEARCH && !userDisabled,
        'writerRoute': user.preferredRole===Role.WRITER && !userDisabled,
        'authenticatedRoute': isLogged && !userDisabled,
        'onlyUnauthenticatedRoute': !isLogged,
        'allowDisabled': true,
        'googleTeacherRoute':  user.preferredRole===Role.GOOGLE_TEACHER && !userDisabled,
        'googleStudentRoute': (user.preferredRole===Role.GOOGLE_STUDENT && !userDisabled) || user.preferredRole===Role.GOOGLE_TEACHER,
        'canvasTeacherRoute': user.preferredRole===Role.CANVAS_TEACHER && !userDisabled,
        'canvasStudentRoute': (user.preferredRole===Role.CANVAS_STUDENT && !userDisabled) || user.preferredRole===Role.CANVAS_TEACHER,}
        :
        {'adiRoute': isAdiSuperAdmin || isAdiAdmin,
        'studentRoute': isStudent,
        'teacherRoute': (isTeacherOrFacilitator || isFacilitator || isSubscriptionUser) && !userDisabled,
        'teacherAssistantRoute': isTeacherAssistant && !userDisabled,
        'organizationRoute': isOrganizationAdiAdmin,
        'advancedSearchRoute': isAdvancedSearch && !userDisabled,
        'writerRoute': isWriter && !userDisabled,
        'authenticatedRoute': isLogged && !userDisabled,
        'onlyUnauthenticatedRoute': !isLogged,
        'allowDisabled': true,
        'googleTeacherRoute':  isGoogleTeacher && !userDisabled,
        'googleStudentRoute': (isGoogleStudent && !userDisabled) || isGoogleTeacher,
        'canvasTeacherRoute': isCanvasTeacher && !userDisabled,
        'canvasStudentRoute': (isCanvasStudent && !userDisabled) || isCanvasTeacher,};

      return routeConditions[routeType] ?? true;
    },
    [
      isAdiSuperAdmin,
      isStudent,
      isTeacherOrFacilitator,
      isTeacherAssistant,
      isOrganizationAdiAdmin,
      isAdiAdmin,
      isLogged,
      isWriter,
      isFacilitator,
      isAdvancedSearch,
      isSubscriptionUser,
      userDisabled,
      isGoogleStudent,
      isGoogleTeacher,
      isCanvasTeacher,
      isCanvasStudent,
      user
    ],
  );

  let hasAccess = useMemo(() => {
    let allow = routeTypes.length === 0 ? true : false;
    routeTypes.forEach((routeType: string) => {
      if (checkPermission(routeType)) allow = true;
    });

    return allow;
  }, [checkPermission, routeTypes]);

  let redirectRoute: string;
  if (isAdvancedSearch) redirectRoute = '/advanced-search/home';
  else if (isStudent) redirectRoute = '/student-dashboard';
  else if (isGoogleTeacher) redirectRoute = '/googleclassroom-teacher-dashboard';
  else if (isGoogleStudent && !isGoogleTeacher) redirectRoute = '/googleclassroom-students-dashboard';
  else if (isCanvasTeacher) redirectRoute = '/canvas-teacher-dashboard';
  else if (isCanvasStudent && !isCanvasTeacher) redirectRoute = '/canvas-students-dashboard';
  else if (isTeacherOrFacilitator || isTeacherAssistant) redirectRoute = '/teacher-dashboard';
  else if (isAdiSuperAdmin || isAdiAdmin) redirectRoute = '/adi-dashboard';
  else if (isOrganizationAdiAdmin && !isTeacherOrFacilitator)
    redirectRoute = !user.subscription?.reportPermission ? '/organization-users' : '/organization-dashboard';
  else if (isWriter && !isTeacherOrFacilitator) redirectRoute = '/organization-library';

  const defaultDisabledUserRoute = '/billing-information';

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (onlyUnauthenticated) {
          if (isLogged) {
            return <Redirect to={redirectRoute} />;
          } else {
            return <Component />;
          }
        }

        if (hasAccess) {
          dispatch(menuActions.setCurrentMenu(root || location.pathname));
          return <Component />;
        }

        if (userDisabled) {
          return <Redirect to={defaultDisabledUserRoute} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
