import { useMutation, useQuery } from '@apollo/client';
import { message, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import CircularSpin from '../../../shared/CircularSpin';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Sider from '../../../shared/Sider';
import Spacer from '../../../shared/Spacer';
import UploadInput from '../../../shared/UploadInput';
import {
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationCatalogStepEntry,
  GQL_InvestigationCatalogActivityEntry,
  GQL_Mode,
  GQL_InvestigationCatalogStepMaterial,
} from '../../../types/investigation';
import { clearKeyFromObject } from '../../../utils/investigation';
import { themeConfig } from '../../../utils/theme';
import InvestigationDelete from '../InvestigationDelete';
import * as S from './styles';

interface IStagesSettingsSider {
  investigationId: string;
  initialStageId?: string;
}

const stageMaterialToUploadFile = (materials?: GQL_InvestigationCatalogStepMaterial[]): UploadFile[] => {
  if (!materials) return [];

  return materials.map((material) => ({
    uid: material.id,
    name: material.filename,
    status: 'done',
    url: material.url,
  }));
};

const StagesSettingsSider = (props: IStagesSettingsSider) => {
  const { investigationId, initialStageId } = props;
  const history = useHistory();
  const [stageMaterials, setStageMaterials] = useState<UploadFile[]>([]);
  const [selectedStageId, setSelectedStageId] = useState<string | undefined>(initialStageId);
  const [isAssessment, setIsAssessment] = useState<boolean>(false);
  const [stages, setStages] = useState<GQL_InvestigationCatalogStepEntry[]>();
  const selectedStage = stages?.find((stage) => stage.id === selectedStageId);

  const investigationTitle = isAssessment ? 'Assessment' : 'Investigation';
  const activityTitle = isAssessment ? 'Prompt' : 'Activity';
  const stageErrorTitle = isAssessment ? 'part' : 'stage';
  const stageTitle = isAssessment ? 'Assessment' : 'Stage';
  const inPersonModeTitle = isAssessment ? 'Paper' : 'In-Person';
  const remoteModeTitle = isAssessment ? 'Paperless' : 'Remote';

  useEffect(() => {
    const selectedStage = stages?.find((stage) => stage.id === selectedStageId);
    const rcFiles: UploadFile[] = stageMaterialToUploadFile(selectedStage?.materials);

    setStageMaterials(rcFiles);
  }, [selectedStageId, stages]);

  useQuery<{ getInvestigationDraftById: GQL_InvestigationCatalogEntry }, { id: string }>(
    gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationDraftById,
    {
      variables: {
        id: investigationId,
      },
      onCompleted: ({ getInvestigationDraftById }) => {
        setStages(clearKeyFromObject(getInvestigationDraftById).steps);
        setIsAssessment(clearKeyFromObject(getInvestigationDraftById).isAssessment);
      },
      onError: (err) => {
        message.error(
          `There was an error getting the ${investigationTitle.toLowerCase()}: ` + err.message || 'Unexpected Error',
        );
        history.goBack();
      },
    },
  );

  const [editStepDraft, { loading: loadingEditStage }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.DRAFT.editStepDraft,
    {
      onError: (err) => {
        message.error(
          `There was an error saving the ${investigationTitle.toLowerCase()}: ` + err.message || 'Unexpected Error',
        );
      },
      onCompleted: () => message.success(`${investigationTitle} updated successfully`),
    },
  );

  const [deleteActivity, { loading: loadingDeleteActivity }] = useMutation<{
    deleteActivityDraft: GQL_InvestigationCatalogStepEntry;
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.deleteActivityDraft, {
    onCompleted: (data) => {
      setStages(
        stages?.map((stage) => {
          if (stage.id === selectedStageId) {
            return {
              ...stage,
              activities: data?.deleteActivityDraft?.activities || [],
            };
          } else {
            return stage;
          }
        }),
      );
    },
    onError: (err) => {
      message.error(`There was an error deleting ${activityTitle.toLowerCase()}: ` + err.message || 'Unexpected Error');
    },
  });

  const [addActivityToStep, { loading: loadingAddActivity }] = useMutation<{
    editActivityDraft: GQL_InvestigationCatalogActivityEntry;
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.editActivityDraft, {
    onError: (err) => {
      message.error(
        `There was an error creating the ${activityTitle.toLowerCase()}: ` + err.message || 'Unexpected Error',
      );
    },
    update: (cache, { data }) => {
      cache.writeFragment({
        id: `InvestigationCatalogStepEntry:${data?.editActivityDraft?.stepId}`,
        fragmentName: 'updateStepEntry',
        fragment: gqlSchema.InvestigationSchema.fragments.DRAFT.updateStepEntry,
        data: {
          materials: [...(selectedStage?.materials || [])],
          activities: [...(selectedStage?.activities || []), data?.editActivityDraft],
        },
      });
    },
  });

  const [uploadStepMaterialContent] = useMutation<
    {
      uploadStepMaterialContent: { materials: GQL_InvestigationCatalogStepMaterial[] };
    },
    {
      investigationDraftId: string;
      stepDraftId: string;
      files: UploadFile[];
    }
  >(gqlSchema.InvestigationSchema.mutations.DRAFT.uploadStepMaterialContent, {
    onError: (err) => {
      message.error(`There was an error uploading ${stageErrorTitle} materials: ` + err.message || 'Unexpected Error');
    },
  });

  const [deleteStepMaterialContent] = useMutation<
    { deleteStepMaterialContent: { id: string; materials: any } },
    { materialId: string }
  >(gqlSchema.InvestigationSchema.mutations.DRAFT.deleteStepMaterialContent, {
    onError: (err) => {
      message.error(`There was an error deleting ${stageErrorTitle} materials: ` + err.message || 'Unexpected Error');
    },
  });

  const beforeUploadStageMaterials = (file: RcFile, fileList: RcFile[]) => {
    if (!file.type.includes('pdf')) return false;

    return true;
  };

  const handleCreateActivity = async () => {
    if (selectedStage && !loadingAddActivity) {
      const biggestActivityOrder = selectedStage.activities.reduce((b, a) => (a.order > b ? a.order : b), 0);

      const { data } = await addActivityToStep({
        variables: {
          activityDraft: {
            name: `${isAssessment ? 'Part' : 'Investigation'} ${biggestActivityOrder + 1}`,
            order: biggestActivityOrder + 1,
            content: [],
          },
          investigationDraftId: investigationId,
          stepDraftId: selectedStageId,
        },
      });

      if (!data) return;

      setStages(
        stages?.map((stage) => {
          if (stage.id === selectedStageId) {
            return {
              ...stage,
              activities: [...stage.activities, { ...data.editActivityDraft, __typename: undefined }],
            };
          } else {
            return stage;
          }
        }),
      );
    }
  };

  const handleDeleteActivity = (activityId: string) => {
    deleteActivity({
      variables: {
        activityDraftId: activityId,
        investigationDraftId: investigationId,
      },
    });
  };

  const handleSaveStages = async () => {
    const selectedStage = stages?.find((stage) => stage.id === selectedStageId);
    if (selectedStage && selectedStageId) {
      const cleanSelectedStage = clearKeyFromObject(selectedStage, [
        '__typename',
        'stepId',
        'completedAt',
        'peerReviewId',
        'rating',
        'reflection',
        'review',
        'answer',
        'readyToReview',
        'feedback',
        'MIMEtype',
        'reflectionCompletedAt',
        'hidden',
        'materials',
        'activityId',
      ]);
      await editStepDraft({
        variables: {
          stepDraft: {
            ...cleanSelectedStage,
            materials: undefined,
          },
          investigationDraftId: investigationId,
        },
      });

      setStages(
        stages?.map((s) => {
          if (s.id !== selectedStageId) return s;

          return {
            ...s,
          };
        }),
      );
    }
  };

  const handleSelectStageMode = (mode: GQL_Mode) => {
    setStages(
      stages?.map((s) => {
        if (s.id !== selectedStageId) return s;

        return {
          ...s,
          mode,
        };
      }),
    );
  };

  if (!stages) return <Spin />;

  return (
    <Sider
      title={
        <S.SiderTitle
          onClick={(e) => {
            e.stopPropagation();
            history.push('core');
          }}
        >
          <FiArrowLeft size={22} />
          <Spacer axis="horizontal" />
          {`${investigationTitle} ${isAssessment ? 'Settings' : 'Stages'}`}
        </S.SiderTitle>
      }
    >
      <S.SiderContainer>
        <S.SettingsContainer>
          {!isAssessment ? (
            <>
              <Select
                placeholder="Select A Stage"
                value={selectedStageId}
                onChange={(v) => setSelectedStageId(v as string)}
              >
                {stages?.map((stage, i) => (
                  <SelectOption value={stage.id} key={stage.id}>
                    Stage {i + 1}: {stage.name || 'No Name'}
                  </SelectOption>
                ))}
              </Select>
              <Spacer />
              <h2>Stage Name</h2>
              <Input placeholder="Name" backgroundColor="white" value={selectedStage?.name} disabled />
              <Spacer />
            </>
          ) : null}
          <h2>{`${stageTitle} States`}</h2>
          <Select
            placeholder={`${stageTitle} States`}
            value={selectedStage?.mode}
            onSelect={(v) => handleSelectStageMode(v as GQL_Mode)}
          >
            <SelectOption value="ALL">{`Both ${inPersonModeTitle} and ${remoteModeTitle}`}</SelectOption>
            <SelectOption value="INPERSON">{`${inPersonModeTitle} Only`}</SelectOption>
            <SelectOption value="REMOTE">{`${remoteModeTitle} Only`}</SelectOption>
          </Select>
          <Spacer />
          <h2>{`${stageTitle} Material`}</h2>
          <UploadInput
            title={`Click to Add the ${stageTitle} Materials`}
            accept=".pdf"
            beforeUpload={beforeUploadStageMaterials}
            defaultFileList={stageMaterials}
            fileList={stageMaterials}
            multiple
            onRemove={async (m) => {
              try {
                if (m.url) {
                  setStageMaterials(
                    stageMaterials.map((sM) => {
                      if (sM.uid !== m.uid) return sM;

                      return {
                        ...sM,
                        status: 'uploading',
                      };
                    }),
                  );
                  await deleteStepMaterialContent({
                    variables: {
                      materialId: m.uid,
                    },
                  });
                }
                setStages(
                  stages.map((s) => {
                    if (s.id !== selectedStageId) return s;

                    return {
                      ...s,
                      materials: stageMaterials
                        .filter((material) => material.uid !== m.uid)
                        .map((m) => ({
                          id: m.uid,
                          filename: m.name || m.fileName || '',
                          MIMEType: '',
                          url: m.url || '',
                          created: 0,
                        })),
                    };
                  }),
                );
                return true;
              } catch (_) {
                message.error(`Error removing ${stageTitle} material`);
                setStageMaterials(stageMaterialToUploadFile(stages?.find((s) => s.id === selectedStageId)?.materials));
                return false;
              }
            }}
            customRequest={async (options) => {
              const file = options.file as RcFile;
              if (selectedStageId && file) {
                try {
                  setStageMaterials([{ uid: file.uid, name: file.name, status: 'uploading' }, ...stageMaterials]);

                  const response = await uploadStepMaterialContent({
                    variables: {
                      investigationDraftId: investigationId,
                      stepDraftId: selectedStageId,
                      files: [file],
                    },
                  });

                  const materialList = response?.data?.uploadStepMaterialContent?.materials;

                  setStageMaterials(stageMaterialToUploadFile(materialList));

                  setStages(
                    stages?.map((s) => {
                      if (s.id !== selectedStageId) return s;

                      return {
                        ...s,
                        materials: materialList || [],
                      };
                    }),
                  );
                } catch (err) {
                  message.error(`Error uploading ${stageTitle} material`);
                  setStageMaterials(
                    stageMaterialToUploadFile(stages?.find((s) => s.id === selectedStageId)?.materials),
                  );
                }
              }
            }}
          />
          <Spacer />
          <h2>{`${stageTitle} ${activityTitle}`}</h2>
          {selectedStage?.activities?.map((activity) => (
            <React.Fragment key={activity.id}>
              <S.ActivityContainer
                onClick={() =>
                  history.push(
                    isAssessment ? `settings/${selectedStage?.id}/parts` : `stages/${selectedStage?.id}/activities`,
                    {
                      initialActivityId: activity.id,
                    },
                  )
                }
              >
                {activity.name}
                <span role="none" >
                  <InvestigationDelete
                    handleDeleteActivity={() => handleDeleteActivity(activity.id)}
                    loading={loadingDeleteActivity}
                    title={
                      isAssessment
                        ? 'Are you sure you want to delete this part?'
                        : 'Are you sure you want to delete this activity?'
                    }
                  />
                  <Spacer axis="horizontal" />
                  <FiArrowRight size={20} />
                </span>
              </S.ActivityContainer>
              <Spacer />
            </React.Fragment>
          )) ||
            `Please, select an ${investigationTitle.toLowerCase()} to see it's ${
              isAssessment ? 'parts' : 'activities'
            }`}
          <S.ActivityContainer onClick={handleCreateActivity}>
            {`Add ${isAssessment ? 'more parts' : 'Activity'}`}
            {loadingAddActivity ? <CircularSpin size={20} color="black" /> : <FiPlus size={20} />}
          </S.ActivityContainer>
        </S.SettingsContainer>

        <S.ButtonsContainer>
          <Button text="Go Back" theme={themeConfig.noColor} block onClick={() => history.goBack()} />
          <Spacer axis="horizontal" size={24} />
          <Button text="Save Settings" block onClick={handleSaveStages} loading={loadingEditStage} />
        </S.ButtonsContainer>
      </S.SiderContainer>
    </Sider>
  );
};

export default StagesSettingsSider;
