import { useMutation, useQuery } from '@apollo/client';
import { message } from 'antd';
import React, { useCallback, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import PageWithTitle from '../../shared/PageWithTitle';
import { GQL_InvestigationSummaryResponse } from '../../types/teacher';
import * as S from './styles';
import { AssessmentSummary } from './AssessmentSummary';
import { AssessmentActions } from './AssessmentActions';
import { AssessmentSummaryTable } from './AssessmentSummaryTable';
import Button from '../../shared/Button';
import { themeConfig } from '../../utils/theme';
import ModalConfirm from '../../shared/ModalConfirm';

type Props = RouteComponentProps<
  {
    assessmentId: string;
    submissionVersion?: string;
  },
  any,
  {
    classId?: string;
  }
>;

const AssessmentSummaryDashboard: React.FC<Props> = (props) => {
  const { assessmentId } = props.match.params;
  const submissionVersion = parseInt(props.match.params.submissionVersion || '1');
  const { classId } = props.location.state || {};
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  
  const history = useHistory();
  const { isTeacherOrFacilitator, isFacilitator, user} = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const { data: assessmentData, loading, refetch } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getAssessmentSummary, {
    variables: {
      id: assessmentId,
    },
    onError: () => {
      message.error(
        'There was an error loading the assessment summary, please check your connection and try again later',
      );
    },
  });

  const [removeAssessment, { loading: loadingRemoveAssessment }] = useMutation<
    { removeInvestigationFromClasses: boolean },
    { id: string }
  >(gqlSchema.InvestigationSchema.mutations.DELETE.removeInvestigationFromClasses, {
    onCompleted: (data) => {
      if (data.removeInvestigationFromClasses) {
        history.push('/teacher-dashboard');
        message.success(`Assessment deleted successfully`);
      } else {
        message.error('There was an error trying to delete the assessment, please try again later');
      }
    },
    onError: (err) => {
      message.error('There was an error trying to delete the assessment, please try again later');
    },
  });

  const onRemoveInvestigation = useCallback(() => {
    removeAssessment({ variables: { id: assessmentId } });
  }, [assessmentId, removeAssessment]);

  const assessmentSummary = assessmentData?.getInvestigationProgressSummary;
  const type =
    assessmentData?.getInvestigationProgressSummary?.discipline?.subject === 'Engineering'
      ? 'Design Challenge'
      : 'Assessment';

  return (
    <>
      <PageWithTitle
        title={<S.TitleContainer>{assessmentSummary ? assessmentSummary.title : `${type} Settings`}</S.TitleContainer>}
        backPageUrl={classId ? `/teacher-dashboard/class/${classId}` : '/teacher-dashboard'}
        extra={
          (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) ? (
            <Button
              data-cy="components-assessmentsummary-delete-button"
              text="Delete Assessment"
              style={{ float: 'right' }}
              theme={themeConfig.orangePrimary}
              onClick={() => setDeleteConfirmVisible(true)}
            />
          ) : null
        }
      >
        <AssessmentSummary
          assessmentData={assessmentSummary}
          loading={loading}
          type={type}
          isFacilitator={isFacilitator}
        />

        <AssessmentActions
          assessmentId={assessmentId}
          assessmentData={assessmentSummary}
          isTeacherOrFacilitator={isTeacherOrFacilitator}
        />
        <AssessmentSummaryTable
          assessmentId={assessmentId}
          assessmentData={assessmentSummary}
          loading={loading}
          handleOnUpdate={() => refetch()}
          submissionVersion={submissionVersion}
        />

      <ModalConfirm
        visible={deleteConfirmVisible}
        setVisible={setDeleteConfirmVisible}
        title={`You are deleting ${assessmentSummary?.title?.toLocaleLowerCase()}`}
        lineInfo1={`Are you sure you want to delete ${assessmentSummary?.title?.toLocaleLowerCase()} for ${assessmentSummary?.class}?`}
        lineInfo2={`All the progress and grades will be permanently deleted`}
        deleteButtonTitle={`Delete Assessment`}
        nameToCheck={assessmentSummary?.title || ''}
        loading={loadingRemoveAssessment}
        deleteFn={onRemoveInvestigation}
        errorMessage={`The Assesment name is not the same`}
      />
      </PageWithTitle>
    </>
  );
};

export default withRouter(AssessmentSummaryDashboard);
